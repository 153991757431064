<div class="custom-input flex">
  <p-dropdown
    [options]="tzArr"
    [placeholder]="placeholder"
    [filter]="true"
    [resetFilterOnHide]="true"
    filterBy="name"
    class="w-full"
    [ngClass]="{
      'is-invalid-dropdown': invalid
    }"
    [(ngModel)]="selectedTzItem"
    (onChange)="onDropdownChange($event)"
    [disabled]="isDisabled"
    [dropdownIcon]="isDisabled ? 'none' : ''"
    (onHide)="onTouched()"
  >
    <ng-template pTemplate="selectedItem">
      <div
        *ngIf="selectedTzItem"
        class="cr-tz-selected-item-text flex items-center justify-center gap-2"
      >
        <div>{{ selectedTzItem.name }}</div>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="flex items-center gap-2">
        <span>{{ item.name }}</span>
      </div>
    </ng-template>
  </p-dropdown>
</div>
