import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarDetail } from '@modules/car-booking/models/view-details';
import { PotentialSurchargeDTO } from '@car-rental/angular-car-rental-service';
import { FreightTypes } from '@modules/car-ota/components/car-with-driver-management/services/car-with-driver.service';
import { OrderDetailDTO } from '@car-rental/angular-car-rental-order-service';
@Component({
  selector: 'app-journey-information-car-with-driver',
  templateUrl: './journey-information-car-with-driver.component.html',
  styleUrls: ['./journey-information-car-with-driver.component.scss'],
})
export class JourneyInformationCarWithDriverComponent {
  @Input() vehicleData: CarDetail | OrderDetailDTO | any;
  @Input() keepValue: boolean = false;
  @Input() userCurrency: any;
  @Input() vehiclePriceData: any;

  freightTypes = FreightTypes;

  potentialSurchargesTrackBy(index: number, item: PotentialSurchargeDTO) {
    return item.name;
  }
}
