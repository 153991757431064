import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  QueryList,
  ViewChildren,
  Renderer2,
  Input,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CountdownService } from '@modules/car-booking/services/count-down.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MockPropertyBookingInfo } from '@modules/car-booking/mock/property-booking-info';
import { SharedModule } from '@shared/shared.module';
import { formatCurrency } from '@shared/utils/helper';
import { IContactInfo } from '@modules/car-booking/models/contact-info.model';
import { ContactInfoService } from '@modules/car-booking/services/contact-info/contact-info.service';
import { PopupTimeoutComponent } from '../popup-timeout/popup-timeout.component';
import { SpecialRequestsService } from '@modules/car-booking/services/special-requests/special-requests.service';
import { ReservationService } from '@modules/car-booking/services/reservation-service/reservation.service';
import { PaymentData, BookedRoomsList } from '@modules/car-booking/models/payment-data.model';
import { environment } from '@env/environment';
import { CarDetail } from '@modules/car-booking/models/view-details';
import { CommonIconText } from '../common-icon-text/common-icon-text.component';
import { ServiceTypeEnum } from '@modules/car-ota/components/become-agency/components/request-agency/request-agency-form/request-agency-form.component';

@Component({
  selector: 'app-car-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss'],
})
export class CarCancellationPolicyComponent {
  @Input() isDependencyPanel: boolean = true;
  @Input() serviceType: string;
  serviceTypeEnum = ServiceTypeEnum;
  constructor() {}

  ngOnInit(): void {}
}
