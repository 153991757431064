import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { getMyFeatures } from '@soctrip-common/system-features';
import { UserProfileService } from './user-profile.service';
import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemFeatureService implements OnDestroy{
  destroy = new Subject<void>();
  baseUrl: string = environment.baseURL;
  userId: string;
  constructor(private userProfileService: UserProfileService) { }
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
  getFeatureAssigned(feature: string[]){
    this.userProfileService.getData().pipe(takeUntil(this.destroy)).subscribe((value: any) => {
      if(value){
        getMyFeatures(this.baseUrl,value.id, [value.country_code, value.city_code])
        .then((result: any) => {
         feature.map(value => {
          this.isFeatureAssigned(result.features,value);
         });
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
      }
    });
  }
  isFeatureAssigned(data: any,feature: string){
    if(data && data.includes(feature)){
      switch(feature){
        case 'SOC_CHAT':
          environment.SOCCHAT_VISIBLE = true;
          break;
        case 'LOYALTY':
          environment.LOYALTY_VISIBLE = true;
          break;
      }
    }else{
      switch(feature){
        case 'SOC_CHAT':
          environment.SOCCHAT_VISIBLE = false;
          break;
        case 'LOYALTY':
          environment.LOYALTY_VISIBLE = false;
          break;
      }
    }
  }
}
