import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';

export interface Attachment {
  id?: string;
  title: string;
  file: { name: string; id?: string; type?: string; size?: number } | undefined | null;
  isUpload: boolean;
  isValid: boolean;
  type?: string;
  isRequired?: boolean;
  extension: string;
}

export interface IFile {
  id?: string;
  extension?: string;
  type?: string;
  description?: string;
  original_name?: string;
  signature?: string;
}

@Component({
  standalone: true,
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  imports: [TranslateModule, TooltipModule, CommonModule],
})
export class UploadComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() attach: Attachment;
  @Input() readOnly: boolean = false;
  @Input() enablePreview: boolean = false;
  @Output() emitOnClose: EventEmitter<void> = new EventEmitter();
  @Output() emitOnUpload: EventEmitter<any> = new EventEmitter();
  @Output() emitOnDownload: EventEmitter<Attachment> = new EventEmitter();
  @Output() emitOnPreview: EventEmitter<Attachment> = new EventEmitter<Attachment>();
  @Output() emitOnFileTypeError: EventEmitter<void> = new EventEmitter();
  @ViewChild('uploader') uploader: ElementRef;
  unsuportedFileTypeStr = 'unsupported';

  fileName: string;
  isClose: boolean = false;

  private translationService = inject(SoctripTranslationService);

  ngOnInit(): void {
    if (this.attach?.type === 'attach') this.isClose = true;
    if (!this.attach?.file?.name?.match(/\.(jpg|jpeg|png|gif|pdf|doc|docx)$/i))
      this.enablePreview = false;
    setTimeout(() => {
      if (this.attach?.file) {
        this.fileName = this.attach.file.name;
      }
    }, 1000);
  }

  onRemove() {
    this.emitOnClose.emit();
  }

  async uploadFile(e: Event) {
    const input = e.target as HTMLInputElement;
    const img = 'image';
    if (input?.files && input?.files?.length > 0 && input?.files[0]?.type?.startsWith(img)) {
      try {
        const fileMimeType = await this._checkFileMimeType(input.files[0]);
        if (fileMimeType === this.unsuportedFileTypeStr) {
          this.emitOnFileTypeError.emit();
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    this.emitOnUpload.emit(e);
    if (this.attach?.file) {
      this.fileName = this.attach.file.name;
    }
  }

  onDownload(attach: Attachment) {
    this.emitOnDownload.emit(attach!);
  }

  onPreview(attach: Attachment) {
    this.emitOnPreview.emit(attach);
  }

  public clearFile() {
    this.uploader.nativeElement.value = null;
  }

  /**
   * Reference: https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
   * Reference: https://gist.github.com/Qti3e/6341245314bf3513abb080677cd1c93b
   */
  private _checkFileMimeType(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        let type = 'unsupported';
        switch (header) {
          case '89504e47':
            type = 'image/png';
            break;
          case '47494638':
            type = 'image/gif';
            break;
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe3':
          case 'ffd8ffe8':
            type = 'image/jpeg';
            break;
          default:
            type = 'unsupported';
            break;
        }
        resolve(type);
      };

      reader.onerror = (e) => {
        reject('Error reading file');
      };

      reader.readAsArrayBuffer(file.slice(0, 12));
    });
  }

  onConvertTooltip(attach: Attachment) {
    return `${this.translationService.getTranslation(
      this.title ? this.title : attach.title
    )} ${this.translationService.getTranslation(
      attach.isRequired
        ? 'OTA.CONTRACT_AGREEMENT.ATTACHMENT_REQUIRED'
        : 'OTA.CONTRACT_AGREEMENT.ATTACHMENT_OPTIONAL'
    )}`;
  }
}
