import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { DateTime } from 'luxon';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ITimezone {
  id: string;
  name: string;
}

@Component({
  selector: 'app-tzpicker-form-ctrl',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule],
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TzPickerFormCtrlComponent),
      multi: true,
    },
  ],
})
export class TzPickerFormCtrlComponent implements ControlValueAccessor {
  tzData = require('./timezones-default.json');
  tzArr: ITimezone[] = [];
  selectedTzItem: ITimezone | undefined;
  @Input() options: ITimezone[] = [];
  @Input() placeholder: string = 'Select Timezone';
  @Input() isDisabled: boolean = false;
  @Input() invalid: boolean = false;

  ngOnInit() {
    if (this.options.length === 0) this.options = this._getDefaultTimezoneData();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string | null): void {
    this.selectedTzItem = this.options.find((i) => i.id === value) || undefined;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDropdownChange(event: any) {
    this.selectedTzItem = event.value;
    this.onChange(this.selectedTzItem ? this.selectedTzItem.id : null);
    this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {}

  private _getDefaultTimezoneData(isOffsetOnly: boolean = false) {
    this.tzArr = [];
    this.tzData.data.forEach((element: any) => {
      this.tzArr.push({
        id: element.name,
        name: isOffsetOnly
          ? `(${this._generateOffsetByTz(element.name)})`
          : `(${this._generateOffsetByTz(element.name)}) ${element.name}`,
      });
    });
    return this.tzArr;
  }

  private _generateOffsetByTz(timezone: string) {
    const offsetMinutes = DateTime.now().setZone(timezone).offset;
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetRemainingMinutes = Math.abs(offsetMinutes) % 60;
    const sign = offsetMinutes < 0 ? '-' : '+';
    const offsetString: string = `UTC ${sign}${offsetHours
      .toString()
      .padStart(2, '0')}:${offsetRemainingMinutes.toString().padStart(2, '0')}`;
    return offsetString;
  }
}
