import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subject, catchError, finalize, of, takeUntil, timeout } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  AgencyInfoDTO,
  AgencyInfoRequestDTO,
  RentalAgencyControllerService,
  ValidateMediaDataDTO,
} from '@car-rental/angular-car-rental-ota-service';
import { countryCode } from '@modules/users/mock/country-code';
import { InputPhoneNumberComponent } from '@shared/components/input-phone-number/input-phone-number.component';
import { CAR_OTA } from '@shared/constants/routes';
import { TIME_OUT_REQUEST } from '@shared/constant';
import { Attachment, UploadComponent } from '@shared/components/upload/upload.component';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import saveAs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { MediaService } from 'src/app/services/media.service';
import { RegionalSettingsServiceControllerService } from '@soctrip/angular-regional-service';
import { emailRegExp, phoneRegExp } from '@shared/constants/shared-regex';
import { UserRolesResolverService } from '@core/services/user/roles.service';
import { GetApiService } from 'src/app/services/get-api.service';

interface ICountry {
  area_code: string;
  country: string;
  country_code: string;
  id: string;
}

interface ICity {
  city_id: string;
  city_name: string;
  longitude: number;
  latitude: number;
}

export interface IRole {
  name: string;
  key: string;
  icon: string;
  label: string;
}

export enum ContractInfoEnum {
  PERSONAL = 'Personal',
  BUSINESS = 'Business',
  BUSINESS_LICENSE = 'Business license',
  ADD_ATTACHMENT = 'Addition attachment',
  ID_CARD_BACK = 'ID card back view',
  ID_CARD_FRONT = 'ID card front view',
  ERROR = 'error',
  SUCCESS = 'success',
  EDIT = 'edit',
}

export interface IService {
  name: string;
  key: string;
  label: string;
  icon: string;
}

export interface IBase64 {
  id: string;
  data: string;
  isImg: boolean;
  type: string;
  object: any;
}

export enum ServiceTypeEnum {
  CAR_RENTAL = 'CAR_RENTAL',
  CR_WITH_DRIVER = 'CAR_RENTAL_WITH_DRIVER',
  AIRPORT_TRANSFER = 'AIRPORT_TRANSFER',
}

@Component({
  selector: 'app-request-agency-form',
  templateUrl: './request-agency-form.component.html',
  styleUrls: ['./request-agency-form.component.scss'],
  providers: [MessageService, RentalAgencyControllerService],
})
export class RequestAgencyFormComponent {
  @ViewChild('companyPhoneRef') companyPhoneChild: InputPhoneNumberComponent;
  @ViewChild('businessPhoneRef') businessPhoneChild: InputPhoneNumberComponent;
  requestForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    companyEmail: new FormControl(''),
    companyCountry: new FormControl(''),
    companyProvince: new FormControl(''),
    companyDistrict: new FormControl(''),
    companyAddress: new FormControl(''),
    companyAddress2: new FormControl(''),
    fullName: new FormControl(''),
    businessEmail: new FormControl(''),
    role: new FormControl(''),
    currency: new FormControl(''),
    companyPhone: new FormControl(''),
    timezone: new FormControl(''),
    serviceType: new FormControl(''),
  });
  emailRegex: RegExp = emailRegExp;
  phoneRegex: RegExp = phoneRegExp;

  @Output() stepChanged = new EventEmitter<string>();
  @Output() selectedService = new EventEmitter<string | null>();
  @Output() emitAgencyInfo = new EventEmitter<AgencyInfoDTO>();
  onDestroy$: Subject<void> = new Subject();
  phoneCountries: any[];
  countries: any[];
  cities: any[];
  districts: any[];
  isCallAPI: boolean = false;
  isLoading: boolean = false;
  isShowConfirmCancel: boolean;
  roles: IRole[] = [];
  uploadAttach: Array<Attachment>;
  baseUrl = environment.baseURL;
  businessText: string = ContractInfoEnum.BUSINESS;
  personalText: string = ContractInfoEnum.PERSONAL;
  businessLicenseText: string = ContractInfoEnum.BUSINESS_LICENSE;
  additionalAttText: string = ContractInfoEnum.ADD_ATTACHMENT;
  idCardBackText: string = ContractInfoEnum.ID_CARD_BACK;
  idCardFrontText: string = ContractInfoEnum.ID_CARD_FRONT;
  uploadAttachBusiness: Attachment[] = [];
  uploadAttachPersonal: Attachment[] = [];
  maximumAttachBusiness: number = 3;
  maximumAttachPersonal: number = 3;
  fileTypes: Array<string> = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/gif',
    'image/png',
  ];
  isAttachmentsAdded: boolean = false;
  initAttachCommon: Attachment[];
  initAttachPersonal: Attachment[];
  media: ValidateMediaDataDTO[] = [];
  images: IBase64[] = [];
  imagePath: string = `${environment.baseURL}/storage/files/web/`;
  personalImages: IBase64[] = [];
  businessImages: IBase64[] = [];
  currencyList: Array<{ name: string; code: string; currency: string }> = [];
  services: IService[] = [];
  environment = environment;
  selectedServices: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    private agencyControllerService: RentalAgencyControllerService,
    private messageService: MessageService,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private mediaService: MediaService,
    private regionalControllerService: RegionalSettingsServiceControllerService,
    private userRolesResolverService: UserRolesResolverService,
    private getApiService: GetApiService
  ) {
    this.checkAgency();
  }

  ngOnInit(): void {
    this.getCurrencies();
    this.getCountries();
    this.phoneCountries = countryCode;

    this.requestForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      // companyName: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      companyCountry: ['', Validators.required],
      companyProvince: ['', Validators.required],
      companyDistrict: ['', Validators.required],
      companyAddress: ['', Validators.required],
      companyAddress2: [''],
      role: ['', Validators.required],
      currency: ['', Validators.required],
      companyPhone: this.formBuilder.group({
        phone_area_code: [null, Validators.required],
        phone_number: ['', [Validators.required, Validators.pattern(this.phoneRegex)]],
      }),
      // businessEmail: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      timezone: ['', Validators.required],
      serviceType: [[], Validators.required],
    });
    this.initDisplayContract();
    this.requestForm.get('role')?.setValue(this.roles[0].name);
    this.requestForm.get('serviceType')?.setValue([this.services[0].name]);
    this.selectedServices = [this.services[0].name];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  isFormFilled(): boolean {
    const controls = this.requestForm.controls;
    for (const controlName in controls) {
      if (controls.hasOwnProperty(controlName)) {
        const control = controls[controlName];
        if (
          control.valid ||
          control.value ||
          this.companyPhoneChild?.childFormControl.value.phoneNumber ||
          this.businessPhoneChild?.childFormControl.value.phoneNumber
        ) {
          return true;
        }
      }
    }
    return false;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.requestForm.controls;
  }

  onSubmit(): void {
    if (this.requestForm.invalid || !this.selectedService) {
      return;
    } else {
      const agencyRole = this.requestForm.value.role;
      const body = {
        agency_name: this.requestForm.value.fullName,
        agency_email: this.requestForm.value.companyEmail,
        agency_phone: {
          phone_area_code: this.requestForm.value.companyPhone.phone_area_code,
          phone_number: this.requestForm.value.companyPhone.phone_number,
        },
        country: {
          id: this.requestForm.value.companyCountry.id,
          code: this.requestForm.value.companyCountry.country_code,
          name: this.requestForm.value.companyCountry.country,
          longitude: this.requestForm.value.companyCountry.longitude,
          latitude: this.requestForm.value.companyCountry.latitude,
          postal_code: this.requestForm.value.companyCountry.area_code
            ? this.requestForm.value.companyCountry.area_code
            : '',
        },
        province: {
          id: this.requestForm.value.companyProvince.id,
          code: this.requestForm.value.companyProvince.country_code,
          name: this.requestForm.value.companyProvince.region,
          longitude: this.requestForm.value.companyProvince.longitude,
          latitude: this.requestForm.value.companyProvince.latitude,
          postal_code: this.requestForm.value.companyProvince.postal_code
            ? this.requestForm.value.companyProvince.postal_code
            : '',
        },
        district: {
          id: this.requestForm.value.companyDistrict.id,
          code: this.requestForm.value.companyDistrict.code
            ? this.requestForm.value.companyDistrict.code
            : '',
          name: this.requestForm.value.companyDistrict.city_name,
          longitude: this.requestForm.value.companyDistrict.longitude,
          latitude: this.requestForm.value.companyDistrict.latitude,
          postal_code: this.requestForm.value.companyDistrict.zipcodes
            ? this.requestForm.value.companyDistrict.zipcodes[0]
            : '',
        },
        address_line1: this.requestForm.value.companyAddress,
        address_line2: this.requestForm.value.companyAddress2,
        agency_type: agencyRole,
        attachments: this.onFilteredAttachments(this.media),
        currency: this.requestForm.value.currency,
        timezone: this.requestForm.value.timezone,
        service_types: this.requestForm.value.serviceType,
      };
      this.handleBecomeAgency(body);
    }
  }

  checkAgency() {
    this.isLoading = true;
    this.agencyControllerService
      .checkAgency()
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((response) => {
        if (response.success) {
          if (response.data.is_agency) {
            this.router.navigate([
              `${environment.prefix}/${CAR_OTA.BECOME_AGENCY}/${CAR_OTA.SUCCESS}`,
            ]);
          } else {
            this.isLoading = false;
          }
        }
      });
  }

  getIcon(country: any): string {
    return country.icon;
  }

  getCountries() {
    this.regionalControllerService
      .getCountry(true)
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.success && res.data) {
          this.countries = res.data;
        }
      });
  }

  getProvinces(country: ICountry) {
    this.regionalControllerService
      .getRegion(country.country_code)
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.success && res.data) {
          this.cities = res.data;
        }
      });
  }

  getDistricts(provinceName: string) {
    this.regionalControllerService
      .getCity(provinceName)
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.success && res.data) {
          this.districts = res.data;
          this.districts = this.districts.map((district) => ({
            ...district,
            city_name: district.city_name ? district.city_name : district.city,
          }));
        }
      });
  }

  handleChangeCountry(event: any) {
    this.cities = [];
    this.getProvinces(event.value);
    this.districts = [];

    const countryCode = event.value?.country_code;
    if (countryCode) {
      const selectedCountry = this.currencyList.find((country) => country.code === countryCode);

      if (selectedCountry) {
        this.requestForm.get('currency')?.setValue(selectedCountry.currency);
      } else {
        this.requestForm.get('currency')?.setValue('USD');
      }
    }
  }

  handleChangeProvince(event: any) {
    this.getDistricts(event.value?.region!);
  }

  handleClickCancel(): void {
    if (this.isFormFilled()) {
      this.isShowConfirmCancel = true;
    } else {
      this.router.navigate(['']);
    }
    this.selectedService.emit(null);
  }

  handleClosePopup() {
    this.isShowConfirmCancel = false;
  }

  handleConfirm() {
    this.router.navigate(['']);
  }

  async handleBecomeAgency(data: AgencyInfoRequestDTO): Promise<void> {
    this.isCallAPI = true;
    try {
      this.agencyControllerService
        .createPartnership(data)
        .pipe(
          timeout(TIME_OUT_REQUEST),
          takeUntil(this.onDestroy$),
          catchError((error) => {
            if (error.name === 'TimeoutError') {
              this.showError('TIME_OUT_REQUEST');
            } else if (error.status === 0) {
              this.showError('ERR_INTERNET_DISCONNECTED');
            } else {
              this.showError(error.error.error);
            }
            return of(null);
          }),
          finalize(() => {
            this.isCallAPI = false;
          })
        )
        .subscribe((res: any) => {
          if (res) {
            if (res.success) {
              this.stepChanged.emit('request_success');
              this.emitAgencyInfo.emit(res?.data!);
              if (this.media.length > 0) {
                this.validateAttachments(this.media, res.data.id);
              }
              this.userRolesResolverService.resetAgencyRoles();
            } else if (res.error.message) {
              this.showError(res.error.message);
            } else {
              this.showError(res.error);
            }
          }
        });
    } catch (error: any) {
      //console.log(error);
    }
  }

  showError(detail: string) {
    this.messageService.add({
      key: 'FILE_ERROR',
      severity: 'error',
      detail: detail,
    });
  }

  initDisplayContract() {
    this.roles = [
      {
        label: 'OTA.CONTRACT_AGREEMENT.BUSINESS',
        name: ContractInfoEnum.BUSINESS,
        key: '0',
        icon: 'sctr-icon-building-03',
      },
      {
        label: 'OTA.CONTRACT_AGREEMENT.PERSONAL',
        name: ContractInfoEnum.PERSONAL,
        key: '1',
        icon: 'sctr-icon-user-01',
      },
    ];
    this.services = [
      {
        label: 'OTA.BECOME_AN_AGENCY.SERVICES_TYPE.CAR_RENTAL',
        name: ServiceTypeEnum.CAR_RENTAL,
        key: '0',
        icon: 'sctr-icon-car-01',
      },
      {
        label: 'OTA.BECOME_AN_AGENCY.SERVICES_TYPE.CR_WITH_DRIVER',
        name: ServiceTypeEnum.CR_WITH_DRIVER,
        key: '1',
        icon: 'sctr-icon-user-01',
      },
      {
        label: 'OTA.BECOME_AN_AGENCY.SERVICES_TYPE.AIRPORT_TRANSFER',
        name: ServiceTypeEnum.AIRPORT_TRANSFER,
        key: '2',
        icon: 'sctr-icon-plane',
      },
    ];
    this.setDefaultAttachments();
  }

  setDefaultAttachments() {
    this.uploadAttachBusiness = [
      {
        id: uuidv4(),
        title: this.businessLicenseText,
        file: null,
        isUpload: false,
        isValid: false,
        type: 'business',
        extension: '',
      },
      {
        id: uuidv4(),
        title: this.idCardBackText,
        file: null,
        isUpload: false,
        isValid: false,
        type: 'business',
        extension: '',
      },
      {
        id: uuidv4(),
        title: this.idCardFrontText,
        file: null,
        isUpload: false,
        isValid: false,
        type: 'business',
        extension: '',
      },
    ];
    this.uploadAttachPersonal = [
      {
        id: uuidv4(),
        title: this.idCardBackText,
        file: null,
        isUpload: false,
        isValid: false,
        type: 'person',
        extension: '',
      },
      {
        id: uuidv4(),
        title: this.idCardFrontText,
        file: null,
        isUpload: false,
        isValid: false,
        type: 'person',
        extension: '',
      },
    ];
  }

  handleSelectRoleClick(role: IRole) {
    this.setDefaultAttachments();
    this.media = [];
    this.images = [];
    this.businessImages = [];
    this.personalImages = [];
    this.requestForm.controls['role'].setValue(role.name);
    this.getImages(this.requestForm.value.role.toLowerCase());
  }

  handleSelectService(service: IService) {
    const index = this.selectedServices.indexOf(service.name);

    if (index !== -1) {
      this.selectedServices.splice(index, 1);
    } else {
      this.selectedServices.push(service.name);
    }
    this.requestForm.controls['serviceType'].setValue(this.selectedServices as string[]);
  }

  addClassActiveRole(role: IRole) {
    return {
      'text-primary-600': role.name === this.requestForm.value.role,
      [role.icon]: true,
    };
  }

  addClassActiveService(service: IService) {
    return {
      'text-primary-600': this.requestForm.value.serviceType.includes(service.name),
      [service.icon]: true,
    };
  }

  roleTrackBy(index: number, item: IRole) {
    return item.name;
  }

  onRemoveUploadAttachments(index: number, type: string) {
    if (type === this.personalText.toLowerCase()) {
      this.uploadAttachPersonal.splice(index, 1);
    } else if (type === this.businessText.toLowerCase()) {
      this.uploadAttachBusiness.splice(index, 1);
    }
    this.media.splice(index, 1);
    this.getImages(this.requestForm.value.role.toLowerCase());
    this.reorderAdditionalAttachments(type);
  }

  onUploadFile(e: Event, type: string, index: number, uploader?: UploadComponent) {
    const isBusinessRole = type === this.businessText.toLowerCase() ? true : false;
    let data = {
      id: uuidv4(),
      data: '',
      isImg: false,
      type: '',
      object: {},
    };
    const inputElement = e.target as HTMLInputElement;
    const file: File = inputElement!.files![0];
    let render = new FileReader();

    if (!this.fileTypes.includes(file.type)) {
      this.messageService.add({
        key: 'FILE_ERROR',
        severity: 'error',
        detail: this.translate.instant('common.error.only_several_type_suported'),
      });
      if (uploader) uploader.clearFile();
    } else if (file.size >= 5000000) {
      this.messageService.add({
        key: 'FILE_ERROR',
        severity: 'error',
        detail: this.translate.instant('common.error.reach_maximum_file_size_5MB'),
      });
      if (uploader) uploader.clearFile();
    } else if (this.validateFileExists(file, type)) {
      this.messageService.add({
        key: 'FILE_ERROR',
        severity: 'error',
        detail: this.translate.instant('common.error.selected_file_already_uploaded'),
      });
    } else {
      render.readAsDataURL(inputElement!.files![0]);
      if (file.type.startsWith('image')) {
        render.onload = (event: any) => {
          data = {
            id: data.id,
            data: event.target.result,
            isImg: true,
            type: inputElement!.files![0].type,
            object: inputElement!.files![0],
          };
          if (isBusinessRole) {
            this.businessImages.push(data);
          } else {
            this.personalImages.push(data);
          }
        };
      }
      if (isBusinessRole) {
        this.uploadAttachBusiness[index].file = file;
        this.uploadAttachBusiness[index].isUpload = false;
        this.handleUpload();
      } else {
        this.uploadAttachPersonal[index].file = file;
        this.uploadAttachPersonal[index].isUpload = false;
        this.handleUpload();
      }
    }
    this.isAttachmentsAdded = this.checkFilesValid();
  }

  onDownloadFile(attach: Attachment) {
    const id = attach.id!;
    const name = attach.file!.name!;
    const fileUrl = `${this.baseUrl}/storage/files/download/${id}`;
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((val) => {
      saveAs(val, name);
    });
  }

  addUploadAttachments(title: string, isUpload: false, isValid: false, type: string) {
    const key = title;
    const result = `${key} ${
      type === this.businessText.toLowerCase()
        ? this.uploadAttachBusiness.length - 2
        : this.uploadAttachPersonal.length - 1
    }`;
    let upload = {
      id: uuidv4(),
      title: result,
      file: null,
      isUpload: isUpload,
      isValid: isValid,
      type: 'attach',
      extension: '',
    };
    if (type === this.personalText.toLowerCase()) {
      this.uploadAttachPersonal.push(upload);
    } else if (type === this.businessText.toLowerCase()) {
      this.uploadAttachBusiness.push(upload);
    }
  }

  getTranslatedLabel(title: string) {
    if (title.includes(this.additionalAttText)) {
      const newTitle = title.replace(
        this.additionalAttText,
        this.translate.instant('OTA.CONTRACT_AGREEMENT.ADDITION_ATTACHMENT')
      );
      return newTitle;
    } else {
      switch (title) {
        case this.businessLicenseText:
          return this.translate.instant('OTA.CONTRACT_AGREEMENT.BUSINESS_LICENSE');
        case this.idCardBackText:
          return this.translate.instant('OTA.CONTRACT_AGREEMENT.B_ID_CARD');
        case this.idCardFrontText:
          return this.translate.instant('OTA.CONTRACT_AGREEMENT.F_ID_CARD');
        default:
          return title;
      }
    }
  }

  validateFileExists(file: File, type: string) {
    let attachmentsExists: Attachment[] = [];
    if (type === this.personalText.toLowerCase()) {
      attachmentsExists = this.uploadAttachPersonal;
    } else if (type === this.businessText.toLowerCase()) {
      attachmentsExists = this.uploadAttachBusiness;
    }
    return attachmentsExists.find((data) => data?.file?.name === file?.name);
  }

  checkFilesValid(): boolean {
    let filesToCheck =
      this.requestForm!.value.role!.toLowerCase() === this.businessText.toLowerCase()
        ? this.uploadAttachBusiness
        : this.uploadAttachPersonal;
    const valid = filesToCheck.every((item) => item.file !== null);
    return valid;
  }

  reorderAdditionalAttachments(type: string) {
    let temp: Attachment[] = [];
    let counter = 1;
    if (type === this.personalText.toLowerCase()) {
      temp = this.uploadAttachPersonal;
    } else if (type === this.businessText.toLowerCase()) {
      temp = this.uploadAttachBusiness;
    }
    temp.forEach((item) => {
      if (item.type === 'attach') {
        item.title = 'Addition attachment ' + counter;
        counter++;
      }
    });

    if (this.media.length > 0) {
      const attachments = this.media
        .filter((item) => item?.description?.startsWith('Addition attachment'))
        .sort((a, b) => a?.description!.localeCompare(b.description!));
      attachments.forEach((item, index) => {
        item.description = `Addition attachment ${index + 1}`;
      });
    }
  }

  getAttachments(role: string) {
    const attachments: ValidateMediaDataDTO[] = [];
    if (
      role === this.businessText &&
      this.uploadAttachBusiness.length >= 1 &&
      this.uploadAttachBusiness[0].file
    ) {
      for (let i = 0; i < this.uploadAttachBusiness.length; i++) {
        attachments.push({
          id: this.media[i]?.id!,
          extension: this.media[i]?.extension!,
          type: this.media[i]?.type!,
          description: this.uploadAttachBusiness[i]?.title!,
          original_name: this.media[i]?.original_name!,
          signature: this.media[i]?.signature!,
        });
      }
    } else if (
      role === this.personalText &&
      this.uploadAttachPersonal.length >= 1 &&
      this.uploadAttachPersonal[0].file
    ) {
      for (let i = 0; i < this.uploadAttachPersonal.length; i++) {
        attachments.push({
          id: this.media[i]?.id!,
          extension: this.media[i]?.extension!,
          type: this.media[i]?.type!,
          description: this.uploadAttachPersonal[i]?.title!,
          original_name: this.media[i]?.original_name!,
          signature: this.media[i]?.signature!,
        });
      }
    }
    return attachments;
  }

  handleUpload() {
    let filesField: any[];
    this.requestForm.value.role.toLowerCase() === this.businessText.toLowerCase()
      ? (filesField = this.uploadAttachBusiness)
      : (filesField = this.uploadAttachPersonal);
    const filesUpload = filesField.filter((item) => !item.isUpload);
    if (filesUpload.length > 0) {
      const formData = new FormData();
      filesUpload.map((file: any) => {
        formData.append('files', file.file);
      });
      this.mediaService.uploadFiles(formData).subscribe({
        next: (response) => {
          if (response.success) {
            if (this.media.length === 0) {
              this.media = response.data;
            } else {
              const returnMedia = JSON.parse(JSON.stringify(response.data));
              filesField.forEach((item, index) => {
                if (!item.isUpload) {
                  const mediaToAdd = returnMedia.shift();
                  this.media.splice(index, 1, mediaToAdd);
                }
              });
            }
            const attachments: Array<any> =
              this.uploadAttachBusiness[0].file || this.uploadAttachPersonal[0].file
                ? this.getAttachments(this.requestForm.value.role!)
                : [];
            this.media = attachments;
            this.getImages(this.requestForm.value.role.toLowerCase());
          } else {
            this.messageService.add({
              key: 'FILE_ERROR',
              severity: 'error',
              detail: this.translate.instant('common.error.upload_attachments_failed'),
            });
          }
        },
        error: (error) => {
          this.messageService.add({
            key: 'FILE_ERROR',
            severity: 'error',
            detail: this.translate.instant('common.error.upload_attachments_failed'),
          });
        },
      });
    }
  }

  checkAllAttachmentsUploaded(role: string): boolean {
    // TODO: Remove validation for attachments when becomming agency based on STA-9688
    return true;
    let uploaded = false;
    if (role === this.personalText.toLowerCase()) {
      uploaded = this.uploadAttachPersonal.every((item) => item.file !== null);
    } else if (role === this.businessText.toLowerCase()) {
      uploaded = this.uploadAttachBusiness.every((item) => item.file !== null);
    }
    return uploaded;
  }

  validateAttachments(attachments: ValidateMediaDataDTO[], agencyId: string) {
    this.agencyControllerService.validateAttachments(attachments, agencyId).subscribe({
      next: (response) => {
        if (response.success) {
          this.messageService.add({
            key: 'FILE_SUCCESS',
            severity: 'success',
            detail: this.translate.instant('OTA.BECOME_AN_AGENCY.VALIDATE_SUCCESS'),
          });
        } else {
          this.messageService.add({
            key: 'FILE_ERROR',
            severity: 'error',
            detail: this.translate.instant('OTA.BECOME_AN_AGENCY.VALIDATE_ATTACHMENTS_FAIL'),
          });
        }
      },
      error: (error) => {
        this.messageService.add({
          key: 'FILE_ERROR',
          severity: 'error',
          detail: this.translate.instant('OTA.BECOME_AN_AGENCY.VALIDATE_ATTACHMENTS_FAIL'),
        });
      },
    });
  }

  getImages(role: string) {
    this.images = [];
    let existingImages: IBase64[] = [];
    if (role === this.personalText.toLowerCase()) {
      existingImages = this.personalImages.filter((image) =>
        this.media.some((m) => m?.original_name! === image?.object?.name!)
      );
      this.personalImages = existingImages;
    } else if (role === this.businessText.toLowerCase()) {
      existingImages = this.businessImages.filter((image) =>
        this.media.some((m) => m?.original_name! === image?.object?.name!)
      );
      this.businessImages = existingImages;
    }
    this.images = existingImages;
  }

  setAgencyPhone(value: any) {
    this.requestForm.get('companyPhone')?.setValue({
      phone_area_code: value.phoneNumberCode.code,
      phone_number: value.phoneNumber,
    });
  }

  onShowErrorForFileType() {
    this.messageService.add({
      key: 'FILE_ERROR',
      severity: 'error',
      detail: this.translate.instant('common.error.attachment_type_error'),
    });
  }

  onFilteredAttachments(attachments: ValidateMediaDataDTO[]) {
    return attachments.filter((att) => att.id);
  }

  serviceTrackBy(index: number, item: IService) {
    return item.name;
  }

  getCurrencies() {
    this.getApiService.getSupportedCountries().subscribe({
      next: (res) => {
        if (res && res.success) {
          this.currencyList = res?.data! || [];
        }
      },
      error: (err) => {
        console.error(err.message);
      },
    });
  }
}
