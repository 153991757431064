import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FooterService } from '@modules/car-booking/services/footer/footer.service';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { environment } from '@env/environment';
import { SharedModule } from '@shared/shared.module';

export interface ContactFooter {
  origin: string;
  phone: string;
  mail: string;
  address: string;
}

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    DialogModule,
    DropdownModule,
    SharedModule,
  ],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  isShowFooter: Boolean;
  visiblePopupChangeLanguage = false;
  disableSearchButtonApply: boolean = true;
  countries: any[] = [];
  languages: any[] = [];
  currencies: any[] = [];

  selectedCountry: any;
  selectedLanguage: any;
  selectedCurrency: any;
  currentCountry: any;
  currentLanguage: any;
  currentCurrency: any;
  currentYear: number = new Date().getFullYear();
  product_name = environment.PRODUCT_NAME;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private footerService: FooterService,
    private soctripTranslationService: SoctripTranslationService
  ) {
    this.subscribeForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}'),
          this.emailValidator,
        ],
      ],
    });
  }
  ngOnInit(): void {
    this.initData();
    this.selectedLanguage = localStorage.getItem('lang') || environment.support_languages[0];
    this.selectedLanguage = this.languages.find((item: any) => item.code === this.selectedLanguage);
    this.currentLanguage = this.selectedLanguage;
    this.currentCurrency = localStorage.getItem('currency');
    this.selectedCurrency = this.currencies.find((item: any) => item.code === this.currentCurrency);
    this.footerService.setCurrency({
      code: this.currentCurrency,
    });
    this.footerService.getIsShowFooter().subscribe((data) => {
      this.isShowFooter = data;
    });
    this.footerService.setFooterSetting({
      region: this.selectedCountry,
      lang: this.currentLanguage,
      currency: this.currentCurrency,
    });
  }

  emailValidator(control: any) {
    if (control.value && /[^a-zA-Z0-9@.]/.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  subscribeForm: FormGroup;
  submittedSubscribe = false;
  currentPage = 'booking';
  isHoveredFacebook = false;
  isHoveredYoutube = false;
  emailRegister: string;
  isRegisterSuccess = false;
  aboutUs: any = {
    contact: {
      phoneNumber: '1800 6868',
      //email: 'contact@soctrip.com',
      email: 'contact@soctrip.com',
      address: '12 Hoang Van Thu st, Ward 8, Tan Binh dist, HCMC',
      language: 'VIE',
    },
    services: [
      {
        name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        link: environment.FLIGHT_URL,
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        link: environment.HOTEL_URL,
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        link: environment.CAR_URL,
      },
      {
        name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        link: environment.TOUR_URL,
      },
      {
        name: 'Restaurant',
        link: environment.ECOMMERCE_FOOD_URL,
      },
      {
        name: 'Shop',
        link: environment.ECOMMERCE_URL,
      },
    ],
    info: [
      {
        name: 'FOOTER.about_us',
        link: `${environment.SOCIAL_APP_URL}/help-center/about-us`,
      },
      {
        name: 'FOOTER.TERM_CONDITION',
        link: `${environment.SOCIAL_APP_URL}/help-center/term`,
      },
      {
        name: 'FOOTER.privacy_policy',
        link: `${environment.SOCIAL_APP_URL}/help-center/privacy`,
      },
      {
        name: 'FOOTER.information_security',
        link: `${environment.SOCIAL_APP_URL}/help-center/privacy`,
      },
      {
        name: 'FOOTER.operational_regulations',
        link: `${environment.SOCIAL_APP_URL}/help-center/operation-rule`,
      },
      {
        name: 'FOOTER.FAQs',
        link: `${environment.SOCIAL_APP_URL}/help-center/send-us-feedback`,
      },
    ],
    otherServices: [
      { name: 'FOOTER.become_our_partner', link: environment.SOCTRIP_CENTER_URL },
      // { name: 'FOOTER.affiliate_program', link: '' },
      { name: 'FOOTER.submit_feedback', link: 'https://forms.gle/eX9Jb2eDMPuw6Hky9' },
    ],
  };

  contact: ContactFooter[] = [
    {
      origin: 'US',
      phone: '+1 402 580 7166',
      mail: 'contact.car.us@soctrip.com',
      address: '5104 N Orange Blossom Trl, Orlando, FL 32810, United States',
    },
    {
      origin: 'Vietnam',
      phone: '+84 1900 571248',
      mail: 'contact.car.vn@soctrip.com',
      address: '400/8B-400/10, Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, TP.HCM, Việt Nam',
    },
    {
      origin: 'India',
      phone: '+91 33 4802 5173',
      mail: 'contact.car.in@soctrip.com',
      address:
        '9th Floor, Aurora Waterfront, Unit No 902, Plot 34/1, GN Block, Kolkata, West Bengal 700091, India',
    },
  ];

  // Validate when click on it Subcribe
  onInputChange(value: string) {
    this.isRegisterSuccess = false;
    this.submittedSubscribe = false;
  }

  onSubmitSubscribe() {
    this.submittedSubscribe = true;
    if (this.subscribeForm.valid) {
      this.isRegisterSuccess = true;
    }
  }

  // Click button Facebook && Youtube
  handleClickPlatform(typePlatform: string) {
    if (typePlatform === 'facebook') {
      alert('This feature is developing.');
    } else {
      alert('This feature is developing.');
    }
  }

  onMouseEnterIcon(typePlatform: string) {
    if (typePlatform === 'facebook') {
      this.isHoveredFacebook = true;
    } else {
      this.isHoveredYoutube = true;
    }
  }

  onMouseLeaveIcon(typePlatform: string) {
    if (typePlatform === 'facebook') {
      this.isHoveredFacebook = false;
    } else {
      this.isHoveredYoutube = false;
    }
  }

  // Set a threshold value (in pixels) to determine when the "Go to Top" button should be shown
  private readonly SCROLL_THRESHOLD = 200;
  showGoToTopButton = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    // Show/hide the "Go to Top" button based on the scroll position
    this.showGoToTopButton = window.scrollY >= this.SCROLL_THRESHOLD;
  }

  handleLinkAbout(typeAbout: any) {
    this.currentPage = 'booking';
    this.router.navigate(['/' + this.currentPage + '/' + typeAbout.link]);
  }

  handleLinkSubscription(typeSubscription: string) {
    this.currentPage = 'booking';
    switch (typeSubscription) {
      case 'Google Play':
        alert('This feature is developing.');
        break;
      case 'App Store':
        alert('This feature is developing.');
        break;
      case 'Registered':
        alert('This feature is developing.');
        break;
      case 'Announced':
        alert('This feature is developing.');
        break;
    }
  }

  handleClickChangeLanguage() {
    this.visiblePopupChangeLanguage = true;
  }
  onCloseModal() {
    this.selectedLanguage = this.currentLanguage;
    this.visiblePopupChangeLanguage = false;
  }

  handleClickApply() {
    this.visiblePopupChangeLanguage = false;
    this.soctripTranslationService.setLanguage(this.selectedLanguage.code);
    this.currentLanguage = this.selectedLanguage;
    this.currentCurrency = this.selectedCurrency.code;
    localStorage.setItem('currency', this.selectedCurrency.code);
    this.footerService.setCurrency({
      code: this.currentCurrency,
    });
    this.footerService.setFooterSetting({
      region: this.selectedCountry,
      lang: this.currentLanguage,
      currency: this.currentCurrency,
    });
  }
  onChangeCountry(event: any) {}

  onChangeLanguage(event: any) {}

  onChangeCurrency(event: any) {}

  initData() {
    this.countries = [
      { name: 'United States', code: 'US' },
      { name: 'Viet Nam', code: 'VN' },
    ];
    this.languages = [
      {
        name: 'English (US)',
        code: 'en',
        country_code: 'US',
        icon_name: 'assets/images/common-icon/us.svg',
      },
      {
        name: 'Tiếng Việt',
        code: 'vi',
        country_code: 'VN',
        icon_name: 'assets/images/common-icon/vn.svg',
      },
    ];
    this.currencies = [
      {
        name: 'US Dollar',
        symbol: '$',
        code: 'USD',
        locale: 'en-US',
        value: 'USD $',
      },
      {
        name: 'Vietnamese Dong',
        symbol: '₫',
        code: 'VND',
        locale: 'vi-VN',
        value: 'VND ₫',
      },
    ];
  }
}
