import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PotentialSurchargeDTO } from '@car-rental/angular-car-rental-service';
import { ICarForm } from '@modules/car-ota/components/car-management/components/create-new-car/create-new-car.component';

export enum FreightTypes {
  DISTANCE = 'DISTANCE',
  HOUR = 'HOUR',
  IS_RENT_BY_DISTANCE = 'is_rent_by_distance',
  IS_RENT_BY_HOUR = 'is_rent_by_hour',
}

export interface IPlace {
  id?: string;
  order?: number;
  name?: string;
  description?: string;
  photos?: string;
  latitude?: number;
  longitude?: number;
  secondary_text?: string;
  address?: string;
  adt_division?: string;
  category?: string;
  website?: string;
  iata_code?: string;
  continent?: string;
  city?: ILocation;
  province?: ILocation;
  country?: ILocation;
}

interface ILocation {
  id?: string;
  code?: string;
  name?: string;
  longitude?: number;
  latitude?: number;
}

export interface PotentialSurchargeExtDTO extends PotentialSurchargeDTO {
  charge_canal?: number;
}

@Injectable({
  providedIn: 'root',
})
export class CarWithDriverService {
  constructor(private fb: FormBuilder) {}

  createCarForm(carInfo?: ICarForm) {
    const form = this.fb.group({
      license_plate: [carInfo?.license_plate || ''],
      automaker: [carInfo?.automaker || '', Validators.required],
      car_model: [carInfo?.car_model || '', Validators.required],
      model_year: [carInfo?.model_year || '', Validators.required],
      number_of_seat: [
        carInfo?.number_of_seat || '',
        [Validators.required, this.greaterThanZeroValidator()],
      ],
      trans: [carInfo?.trans || ''],
      fuels: [carInfo?.fuels || ''],
      fuel_consumption: [carInfo?.fuel_consumption || ''],
      countries: [carInfo?.countries || '', Validators.required],
      provinces: [carInfo?.provinces || '', Validators.required],
      districts: [carInfo?.districts || '', Validators.required],
      timezone: [carInfo?.timezone || '', Validators.required],
      description: this.fb.array(
        [
          this.createMultiLangDescFormGroup(
            'en',
            carInfo?.description?.find((d) => d.code === 'en')?.desc || ''
          ),
          this.createMultiLangDescFormGroup(
            'vi',
            carInfo?.description?.find((d) => d.code === 'vi')?.desc || ''
          ),
          this.createMultiLangDescFormGroup(
            'hi',
            carInfo?.description?.find((d) => d.code === 'hi')?.desc || ''
          ),
        ],
        [this.requireAtLeastOneDesc()]
      ),
      generalAmenChild: [carInfo?.generalAmenChild || [], Validators.required],
      advancedAmenChild: [carInfo?.advancedAmenChild || []],
      termOfUser: this.fb.array(
        [
          this.createMultiLangDescFormGroup(
            'en',
            carInfo?.termOfUser?.find((d) => d.code === 'en')?.desc || ''
          ),
          this.createMultiLangDescFormGroup(
            'vi',
            carInfo?.termOfUser?.find((d) => d.code === 'vi')?.desc || ''
          ),
          this.createMultiLangDescFormGroup(
            'hi',
            carInfo?.termOfUser?.find((d) => d.code === 'hi')?.desc || ''
          ),
        ],
        [this.requireAtLeastOneDesc()]
      ),
      dailyMileageLimit: [
        carInfo?.dailyMileageLimit || null,
        carInfo?.is_rent_by_distance ? [Validators.required, this.greaterThanZeroValidator()] : [],
      ],
      overageFee: [
        carInfo?.overageFee || null,
        carInfo?.is_rent_by_distance ? [Validators.required, this.greaterThanZeroValidator()] : [],
      ],
      carLocation: [carInfo?.carLocation || ''],
      pickupFee: [carInfo?.pickupFee || 0],
      supportPickup: [carInfo?.supportPickup || 0],
      cleaningSurcharge: [carInfo?.cleaningSurcharge || null],
      smellRemovalSurcharge: [carInfo?.smellRemovalSurcharge || null],
      lateReturnFee: [carInfo?.lateReturnFee || null],
      documents: [carInfo?.documents || []],
      collateralStatus: [carInfo?.collateralStatus || null],
      collateral: [carInfo?.collateral || null, [this.greaterThanZeroValidator()]],
      isDoorStepDelivery: carInfo?.isDoorStepDelivery || false,
      carDeliveryFee: [carInfo?.carDeliveryFee || null],
      supportCarDeliveryIn: [carInfo?.supportCarDeliveryIn || null],
      currency: carInfo?.currency,
      is_without_driver: carInfo?.is_without_driver ?? false,
      is_with_driver: carInfo?.is_with_driver ?? true,
      overage_time_fee: [
        carInfo?.overage_time_fee ?? null,
        carInfo?.is_rent_by_hour ? [Validators.required, this.greaterThanZeroValidator()] : [],
      ],
      daily_time_limit: [
        carInfo?.daily_time_limit ?? null,
        carInfo?.is_rent_by_hour ? [Validators.required, this.greaterThanZeroValidator()] : [],
      ],
      name: [carInfo?.name || '', Validators.required],
      service_type: [carInfo?.service_type || 'CAR_RENTAL_WITH_DRIVER', Validators.required],
      is_rent_by_distance: carInfo?.is_rent_by_distance ?? true,
      is_rent_by_hour: carInfo?.is_rent_by_hour ?? false,
      potential_surcharges: this.fb.array(
        carInfo?.potential_surcharges?.map((item, index) =>
          this.createPotentialSurchargesForm(item, index + 1)
        ) || [
          this.createPotentialSurchargesForm({
            order: 1,
            name: '',
            charge: 0,
            charge_canal: 0,
          }),
        ]
      ),
    });

    form.get('is_rent_by_distance')?.valueChanges.subscribe((isRentByDistance) => {
      const dailyDistanceLimitControl = form.get('dailyMileageLimit');
      const overageDistanceFeeControl = form.get('overageFee');

      if (isRentByDistance) {
        dailyDistanceLimitControl?.setValidators([
          Validators.required,
          this.greaterThanZeroValidator(),
        ]);
        overageDistanceFeeControl?.setValidators([
          Validators.required,
          this.greaterThanZeroValidator(),
        ]);
      } else {
        dailyDistanceLimitControl?.clearValidators();
        overageDistanceFeeControl?.clearValidators();
      }

      dailyDistanceLimitControl?.updateValueAndValidity();
      overageDistanceFeeControl?.updateValueAndValidity();
    });

    form.get('is_rent_by_hour')?.valueChanges.subscribe((isRentByDistance) => {
      const dailyTimeLimitControl = form.get('daily_time_limit');
      const overageTimeFeeControl = form.get('overage_time_fee');

      if (isRentByDistance) {
        dailyTimeLimitControl?.setValidators([
          Validators.required,
          this.greaterThanZeroValidator(),
        ]);
        overageTimeFeeControl?.setValidators([
          Validators.required,
          this.greaterThanZeroValidator(),
        ]);
      } else {
        dailyTimeLimitControl?.clearValidators();
        overageTimeFeeControl?.clearValidators();
      }

      dailyTimeLimitControl?.updateValueAndValidity();
      overageTimeFeeControl?.updateValueAndValidity();
    });

    return form;
  }

  createPotentialSurchargesForm(data: PotentialSurchargeExtDTO, order: number = 1) {
    return this.fb.group({
      order: data?.order || order,
      name: data?.name || '',
      charge: data?.charge || 0,
      charge_canal: data?.charge_canal || 0,
    });
  }

  private createMultiLangDescFormGroup(code: string, desc: string): FormGroup {
    return this.fb.group({
      code: [code, Validators.required],
      desc: [desc],
    });
  }

  requireAtLeastOneDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formArray = control as FormArray;

      const hasAtLeastOneDesc = formArray.controls.some((group) => {
        const formGroup = group as FormGroup;
        return (
          formGroup.get('desc')?.value?.trim() !== '' &&
          formGroup.get('desc')?.value !== null &&
          !/^<p>\s*<\/p>$/.test(formGroup.get('desc')?.value?.trim()) &&
          !/^<(h[1-5])>\s*(<\/\1>|<br\s*\/?>\s*<\/\1>)$/.test(formGroup.get('desc')?.value?.trim())
        );
      });
      return hasAtLeastOneDesc ? null : { requireAtLeastOneDesc: true };
    };
  }

  // Accept number > 0
  greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value && value !== 0) {
        return null;
      } else if (isNaN(value) || value <= 0) {
        return { greaterThanZeroError: true };
      } else {
        return null;
      }
    };
  }

  // Accept number >= 0
  greaterThanOrEqualToZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      // If the value is null, do nothing (no validation)
      if (value === null) {
        return null;
      }

      // If the value is not a number or is less than 0, return the error
      if (isNaN(value) || value < 0) {
        return { greaterThanOrEqualZeroError: true };
      }

      // If it's a valid number and >= 0, return null (no error)
      return null;
    };
  }
}
