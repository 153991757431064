<div class="toast-booking">
  <p-toast position="top-right" [key]="'save'">
    <ng-template let-message pTemplate="message">
      <div class="flex flex-col" style="flex: 1">
        <div class="flex gap-3">
          <i
            class="font-semibold"
            [ngClass]="[
              message.severity === 'success' ? 'sctr-icon-check-circle' : 'sctr-icon-alert-circle'
            ]"
            style="font-size: 20px"
          ></i>
          <p class="pb-2 text-md font-medium text-black">
            {{ message.detail }}
          </p>
        </div>
        <div class="pl-[20px]">
          <button
            class="px-[12px] py-[4px] hover:rounded-lg hover:border-solid hover:bg-[#EFF8FF]"
            (click)="openDialog()"
          >
            <p class="text-primary-600 text-md font-semibold">
              {{ 'toast_save_car.change' | translate }}
            </p>
          </button>
        </div>
      </div>
    </ng-template>
  </p-toast>
</div>
<div
  class="hot-deal-item flex cursor-pointer flex-col rounded-lg border font-inter"
  (click)="viewDetails(item.id, item)"
>
  <div class="image-container h-[152px]">
    <img
      class="aspect-[3/2] h-[152px] w-full rounded-t-lg object-cover"
      src="{{ imageUrl }}"
      (error)="replaceWithDefaultImage($event)"
      alt="image"
      data-cy="url-img-hotel"
    />
    <span
      *ngIf="promotion"
      class="absolute !bottom-2 !left-2 rounded-[4px] bg-[#E62E05] !px-2 !py-1 text-center !text-sm font-medium text-white"
      >{{ promotion }}% {{ 'common.term.off' | translate }}</span
    >
    <span
      class="quick-car-rental-bg absolute !left-2 !top-2 flex items-center gap-1 rounded-[4px] !px-2 !py-1 text-center !text-sm font-medium text-white"
    >
      <i class="sctr-icon-solid-zap text-sm text-amber-400"></i>
      {{ 'common.component.booking_details_content.quick_car_rental' | translate }}
    </span>
    <!-- <span class="quick-car-rental-bg absolute !left-2 !top-2 rounded-[4px] !px-2 !py-1 text-center !text-sm font-medium text-white flex items-center gap-1">
      <i class="sctr-icon-solid-check-circle text-green-400 text-sm"></i>
      {{'common.term.component.booking_details_content.free_cancellation' | translate}}
    </span> -->
    <!-- <span
      class="bg-gradient-orange absolute left-2 top-2 rounded-lg p-[6px] text-center !text-sm font-semibold text-white"
      >{{with_without_driver==='with'?('page.ota.availability.with_driver'|translate):''}}
      {{with_without_driver==='without'?('page.ota.availability.without_driver'|translate):''}}
      {{with_without_driver==='with_without'?('page.ota.availability.with_without_driver'|translate):''}}</span -->

    <!-- <img *ngIf="!item.avatar" class="rounded-lg aspect-[16/9] w-full max-h-[118px] object-cover" src="{{baseImageId}}/{{ item.property_image_overview_id }}.webp" (error)="replaceWithDefaultImage($event)" alt=" " data-cy="url-img-hotel"> -->
    <button
      type="button"
      data-cy="btn-favorite"
      class="btn-favorite absolute right-2 top-2 rounded-lg p-[6px] text-center"
      (click)="handleBookmark($event, item)"
    >
      <span *ngIf="isBookmark; then favorite; else unfavorite"></span>
      <ng-template #favorite>
        <img class="!h-4 !w-4" src="assets/images/common-icon/bookmark-close.svg" alt="" />
      </ng-template>
      <ng-template #unfavorite>
        <img class="!h-4 !w-4" src="assets/images/common-icon/bookmark-open.svg" alt="" />
      </ng-template>
    </button>
  </div>
  <div class="flex h-fit flex-col gap-2 rounded-lg bg-white !p-3">
    <div class="flex flex-col gap-2">
      <div
        #hotelName
        [tooltip]="item.vehicle_info?.car_model_name + ' ' + item.vehicle_info?.year_model"
        [isTruncated]="true"
        [position]="TooltipPosition.BELOW"
        class="line-clamp-1 h-[20px] text-[14px] font-semibold leading-5 text-palette-gray-900"
        data-cy="name-hotel"
      >
        {{ item.vehicle_info?.car_model_name }} {{ item.vehicle_info?.year_model }}
      </div>
      <div class="h-[44px]">
        <div
          *ngIf="with_without_driver === 'without' || with_without_driver === 'with_without'"
          class="flex h-full w-full flex-wrap gap-1 overflow-hidden"
        >
          <span
            *ngFor="let tag of tagList"
            class="flex h-min items-center justify-center rounded-full px-1.5 py-0.5 text-[12px] font-medium leading-4 {{
              tag.type
            }}"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>
      <div class="relative flex items-center">
        <common-icon-text
          class="flex !w-full items-center overflow-hidden text-ellipsis"
          [icon]="'sctr-icon-marker-pin-01'"
          [iconStyle]="'text-base !leading-5 mr-1 opacity-70'"
          [text]="getAddress(item)"
          [textStyle]="
            'text-soctrip-small font-normal text-palette-gray-500 whitespace-nowrap text-ellipsis overflow-hidden'
          "
          [dataCyValue]="'address-hotel'"
        ></common-icon-text>
      </div>

      <!-- <div class="h-[20px]">
        <div
          *ngIf="with_without_driver === 'with' || with_without_driver === 'with_without'"
          class="flex h-full w-full flex-wrap gap-2 overflow-hidden"
        >
          <span class="flex items-center gap-1">
            <img src="assets/icons/transmission-icon.svg" />
            <span class="!text-sm text-palette-gray-500">{{
              (item?.is_automatic_transmission
                ? 'SEARCH_RESULT.car_list.automatic'
                : 'SEARCH_RESULT.car_list.manual'
              ) | translate
            }}</span>
          </span>
          <span class="flex items-center gap-1">
            <img src="assets/icons/seat.svg" />
            <span class="!text-sm text-palette-gray-500">{{
              'pluralization.Seat(s)' | translate : { count: item?.seats || 0 }
            }}</span>
          </span>
        </div>
      </div> -->

      <div class="flex gap-2 items-center h-6">
        <div class="icon-service col-span-1 flex h-4 items-center">
          <img src="assets/icons/like.svg" alt="MarkerPin" />
          <span
            class="text-soctrip-small h-4 ml-1 overflow-hidden text-ellipsis font-normal text-palette-gray-500"
            >{{ item.sum_review }}</span
          >
        </div>
        <span class="text-gray-700">•</span>
        <div class="icon-service col-span-1 flex h-4 items-center">
          <img src="assets/icons/trips.svg" alt="MarkerPin" />
          <span
            class="text-soctrip-small h-[18px] ml-1 overflow-hidden text-ellipsis font-normal text-palette-gray-500"
            >{{ item.trips }} {{'APP_OWNER_CONTACT_INFO.trips' | translate}}</span
          >
        </div>
      </div>
    </div>
    <div *ngIf="originPrice || price" class="flex min-h-12 w-full flex-col items-end justify-end">
      <app-price-format
        *ngIf="originPrice > price"
        [keepValue]="userCurrency === item.currency"
        [price]="originPrice"
        [styleClass]="
          'text-palette-gray-500 text-[14px] font-normal whitespace-nowrap text-ellipsis overflow-hidden line-through truncate'
        "
      ></app-price-format>
      <div class="flex items-center">
        <app-price-format
          [keepValue]="userCurrency === item.currency"
          [price]="price"
          [styleClass]="'text-base font-medium !leading-6 truncate text-palette-orange-dark-500'"
        ></app-price-format>
        <span
          class="text-soctrip-small overflow-hidden text-ellipsis font-normal text-palette-gray-500"
          >/{{ 'COMMON.DAY' | translate }}</span
        >
      </div>
    </div>
  </div>
</div>
<app-toast keyToast="hd"></app-toast>
<app-dialog-save-list-car
  [visible]="visible"
  [detailId]="item.id"
  [key]="'hd'"
  (disable)="closeDialog()"
></app-dialog-save-list-car>
