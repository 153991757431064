<div
  class="t-journey-info-car-with-driver shadow-sx col-span-2 mt-3 rounded-xl border border-palette-gray-200 bg-white px-4"
>
  <div class="t-overage-fee">
    <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
      {{ 'APP_JOURNEY_INFORMATION.overage_fee' | soctripTranslate | async }}
    </p>
    <div class="pb-2">
      <div class="col-span-1 flex rounded-md">
        <div class="col-span-1 w-full !text-md font-normal text-palette-gray-700">
          <div *ngIf="vehicleData?.freight_types?.includes(freightTypes.DISTANCE)">
            <div class="grid grid-cols-1 p-2">
              <div class="flex justify-between gap-2">
                <div class="col-span-1">
                  {{ 'APP_JOURNEY_INFORMATION.DAILY_ALLOWABLE_MILEAGE' | soctripTranslate | async }}
                </div>
                <div class="col-span-1 flex justify-end font-medium">
                  {{
                    keepValue
                      ? vehicleData?.vehicle_price_canal.daily_mileage_limit
                      : vehicleData?.vehicle_price.daily_mileage_limit
                  }}
                  km
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 p-2">
              <div class="flex justify-between gap-2">
                <div class="col-span-1">
                  {{
                    'APP_JOURNEY_INFORMATION.overage_fee_for_distance' | soctripTranslate | async
                  }}
                </div>
                <div class="col-span-1 flex justify-end font-medium">
                  <app-price-format
                    [keepValue]="keepValue"
                    [price]="
                      keepValue
                        ? vehicleData?.vehicle_price_canal?.overage_fee
                        : vehicleData?.vehicle_price?.overage_fee
                    "
                  ></app-price-format
                  >/km
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="vehicleData?.freight_types?.includes(freightTypes.HOUR)">
            <div class="grid grid-cols-1 p-2">
              <div class="flex justify-between gap-2">
                <div class="col-span-1">
                  {{ 'APP_JOURNEY_INFORMATION.daily_allowable_hour' | soctripTranslate | async }}
                </div>
                <div class="col-span-1 flex justify-end font-medium">
                  {{ vehicleData?.daily_time_limit }}
                  {{ 'DETAIL.car_with_driver.hours' | soctripTranslate | async }}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 p-2">
              <div class="flex justify-between gap-2">
                <div class="col-span-1">
                  {{ 'APP_JOURNEY_INFORMATION.overage_fee_for_hour' | soctripTranslate | async }}
                </div>
                <div class="col-span-1 flex justify-end font-medium">
                  <app-price-format
                    [keepValue]="keepValue"
                    [price]="
                      keepValue
                        ? vehicleData?.overage_time_fee_canal!
                        : vehicleData?.overage_time_fee!
                    "
                  ></app-price-format
                  >/km
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ 'APP_JOURNEY_INFORMATION.fuel_surcharge' | soctripTranslate | async }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                {{ 'APP_JOURNEY_INFORMATION.deal_with_driver' | soctripTranslate | async }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-t"></div>
  <div *ngIf="vehicleData?.potential_surcharges?.length! > 0" class="t-potential-surcharge">
    <p class="!mb-3 !pt-4 text-base font-semibold text-palette-gray-800">
      {{ 'APP_JOURNEY_INFORMATION.potential_surcharge' | soctripTranslate | async }}
    </p>
    <div class="pb-2">
      <div
        *ngFor="let type of vehicleData?.potential_surcharges; trackBy: potentialSurchargesTrackBy"
        class="col-span-1 flex rounded-md"
      >
        <div class="col-span-1 w-full !text-md font-normal text-palette-gray-700">
          <div class="grid grid-cols-1 p-2">
            <div class="flex justify-between gap-2">
              <div class="col-span-1">
                {{ type.name! }}
              </div>
              <div class="col-span-1 flex justify-end font-medium">
                <app-price-format
                  [keepValue]="keepValue"
                  [price]="keepValue ? type.charge_canal! : type.charge!"
                ></app-price-format
                >/km
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
