<app-toast [keyToast]="'FILE_ERROR'"></app-toast>
<form
  class="ota-custom min-h-[calc(100vh_-_92px) transition-all"
  [formGroup]="requestForm"
  (ngSubmit)="onSubmit()"
>
  <!-- *ngIf="!isLoading" -->
  <div class="!mb-4 rounded-lg border border-gray-200 bg-white !p-6">
    <div class="t-become-agency-header flex items-center justify-between gap-4 rounded-lg !p-6">
      <div class="flex flex-col items-start gap-2">
        <img src="assets/images/car-ota/soctrip-logo.png" alt="Soctrip logo" />
        <h1 class="text-[32px]/[32px] font-bold text-branding-primary-700">
          {{ 'OTA.BECOME_AN_AGENCY.BECOME_AN_AGENCY_TITLE' | translate }}
        </h1>
      </div>
      <img src="assets/images/car-ota/car-rental.png" alt="Car rental image" />
    </div>
  </div>

  <!-- General Information -->
  <div
    class="m-0 !mt-4 flex grid-cols-3 flex-col !gap-6 rounded-lg bg-white !p-6 sm:!grid sm:!gap-0"
  >
    <div class="col-span-1 flex flex-col gap-2">
      <h3 class="text-lg font-semibold leading-7 text-palette-gray-900">
        {{ 'OTA.BECOME_AN_AGENCY.GENERAL_INFO' | translate }}
      </h3>
      <div class="pr-12 text-sm leading-5 text-palette-gray-700">
        {{ 'OTA.BECOME_AN_AGENCY.GENERAL_INFO_CONTENT' | translate }}
      </div>
    </div>
    <div class="col-span-2 flex flex-col gap-4">
      <!-- Service Type -->
      <div class="mt-2 flex flex-col gap-2">
        <label for="rentalOption" class="form-title"
          >{{ 'OTA.BECOME_AN_AGENCY.SERVICES_TYPE.RENTAL_OPTION' | translate }}
          <span class="form-require">*</span></label
        >

        <div class="flex flex-col gap-4 md:flex-row">
          <div
            *ngFor="let service of services; trackBy: serviceTrackBy"
            class="flex h-[56px] w-full cursor-pointer items-center justify-between rounded-lg border px-4 py-3 md:w-1/2"
            [class.active-rule]="requestForm.value.serviceType.includes(service.name)"
            [ngClass]="{
            'bg-primary-50 border-primary-600': requestForm.value.serviceType.includes(service.name),
          }"
            (click)="handleSelectService(service)"
          >
            <div class="flex items-center justify-start gap-3">
              <i
                [ngClass]="addClassActiveService(service)"
                class="text-2xl leading-5 text-gray-500"
              >
              </i>
              <p
                [ngClass]="{
                'text-primary-600': requestForm.value.serviceType.includes(service.name),
              }"
                class="text-md font-medium text-gray-700"
              >
                {{ service.label | translate }}
              </p>
            </div>
            <div>
              <img
                class="!h-5 !w-5"
                src="assets/icons/voucher-payment/{{
                  requestForm.value.serviceType.includes(service.name)
                    ? 'checkbox-active'
                    : 'checkbox'
                }}.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Fullname -->
      <div class="flex flex-col gap-1">
        <label for="fullName" class="form-title"
          >{{ 'OTA.FULL_NAME' | translate }} <span class="form-require">*</span></label
        >
        <input
          type="text"
          id="fullName"
          formControlName="fullName"
          placeholder="{{ 'OTA.ENTER_FULL_NAME' | translate }}"
          class="form-input"
          [ngClass]="{
            'is-invalid': f['fullName'].touched && f['fullName'].errors
          }"
        />
        <div *ngIf="f['fullName'].errors && f['fullName'].touched" class="invalid-feedback">
          <div *ngIf="f['fullName'].errors['required']" class="flex items-center gap-1">
            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
            {{ 'OTA.FULL_NAME' | translate }}
            {{ 'OTA.IS_REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <!-- Company Email & Phone number -->
      <div class="flex flex-col !gap-4 min-[1100px]:flex-row">
        <!-- Email -->
        <div class="flex w-full flex-col gap-1">
          <label for="companyEmail" class="form-title">
            {{ 'OTA.EMAIL' | translate }} <span class="form-require">*</span>
          </label>
          <input
            type="email"
            id="companyEmail"
            formControlName="companyEmail"
            placeholder="{{ 'OTA.ENTER_EMAIL' | translate }}"
            class="form-input"
            [ngClass]="{
              'is-invalid': f['companyEmail'].touched && f['companyEmail'].errors
            }"
          />
          <div
            *ngIf="f['companyEmail'].touched && f['companyEmail'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyEmail'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.EMAIL' | translate }} {{ 'OTA.IS_REQUIRED' | translate }}
            </div>
            <div *ngIf="f['companyEmail'].errors['pattern']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.EMAIL' | translate }} {{ 'OTA.IS_INVALID' | translate }}
            </div>
          </div>
        </div>
        <!-- Phone number -->
        <div class="flex w-full flex-col gap-1">
          <label for="companyPhone" class="form-title">
            {{ 'OTA.PHONE_NUMBER' | translate }}
            <span class="form-require">*</span>
          </label>
          <app-input-phone-number
            #companyPhoneRef
            [id]="'companyPhone'"
            (selectItemCallback)="setAgencyPhone($event)"
          ></app-input-phone-number>
        </div>
      </div>
      <!-- Address line 1 -->
      <div class="flex flex-col gap-4 min-[1100px]:flex-row">
        <div class="flex w-full flex-col gap-1">
          <label for="companyAddress" class="form-title">
            {{ 'OTA.ADDRESS_LINE' | translate }} 1
            <span class="form-require">*</span>
          </label>
          <input
            type="text"
            id="companyAddress"
            formControlName="companyAddress"
            class="form-input"
            placeholder="{{ 'OTA.ENTER_ADDRESS' | translate }} 1"
            [ngClass]="{
              'is-invalid': f['companyAddress'].touched && f['companyAddress'].errors
            }"
          />
          <span class="text-xs text-system-error-600">
            {{ 'OTA.BECOME_AN_AGENCY.ADDRESS_HINT' | translate }}
          </span>
          <div
            *ngIf="f['companyAddress'].touched && f['companyAddress'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyAddress'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.ADDRESS_LINE' | translate }}
              {{ 'OTA.IS_REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- Address line 2 -->
      <div class="flex flex-col gap-4 min-[1100px]:flex-row">
        <div class="flex w-full flex-col gap-1">
          <label for="companyAddress2" class="form-title">
            {{ 'OTA.ADDRESS_LINE' | translate }} 2
            {{ 'OTA.OPTIONAL' | translate }}
          </label>
          <input
            type="text"
            id="companyAddress2"
            formControlName="companyAddress2"
            class="form-input"
            placeholder="{{ 'OTA.ENTER_ADDRESS' | translate }} 2"
            [ngClass]="{
              'is-invalid': f['companyAddress2'].touched && f['companyAddress2'].errors
            }"
          />
          <div
            *ngIf="f['companyAddress2'].touched && f['companyAddress2'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyAddress2'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.ADDRESS_LINE' | translate }}
              {{ 'OTA.IS_REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- Company Adress -->
      <div class="flex flex-col !gap-4 min-[1100px]:flex-row">
        <!-- Country -->
        <div class="custom-input flex w-full basis-1/3 flex-col gap-1">
          <label class="form-title">
            {{ 'OTA.BECOME_AN_AGENCY.COUNTRY_REGION' | translate }}
            <span class="form-require">*</span>
          </label>
          <p-dropdown
            [options]="countries"
            placeholder="{{ 'OTA.BECOME_AN_AGENCY.SELECT_A_COUNTRY_REGION' | translate }}"
            optionLabel="country"
            [filter]="true"
            filterBy="country"
            formControlName="companyCountry"
            (onChange)="handleChangeCountry($event)"
            [ngClass]="{
              'is-invalid': f['companyCountry'].touched && f['companyCountry'].errors
            }"
          >
            <ng-template pTemplate="selectedItem">
              <div
                *ngIf="f['companyCountry'].value.country"
                class="flex items-center justify-center gap-2"
              >
                <div>{{ f['companyCountry'].value.country }}</div>
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex items-center gap-2">
                <span>{{ country.country }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <div
            *ngIf="f['companyCountry'].touched && f['companyCountry'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyCountry'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.COUNTRY' | translate }}
              {{ 'OTA.IS_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <!-- Province -->
        <div class="custom-input flex w-full basis-1/3 flex-col gap-1">
          <label class="form-title">
            {{ 'OTA.BECOME_AN_AGENCY.PROVINCE_STATE' | translate }}
            <span class="form-require">*</span>
          </label>
          <p-dropdown
            [options]="cities"
            placeholder="{{ 'OTA.BECOME_AN_AGENCY.SELECT_A_PROVINCE_STATE' | translate }}"
            optionLabel="region"
            [filter]="true"
            filterBy="region"
            formControlName="companyProvince"
            (onChange)="handleChangeProvince($event)"
            [ngClass]="{
              'is-invalid': cities && f['companyProvince'].touched && f['companyProvince'].errors
            }"
          >
            <ng-template pTemplate="selectedItem">
              <div
                *ngIf="f['companyProvince'].value"
                class="flex items-center justify-center gap-2"
              >
                <div>{{ f['companyProvince'].value.region }}</div>
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex items-center gap-2">
                <span>{{ country.region }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <div
            *ngIf="cities && f['companyProvince'].touched && f['companyProvince'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyProvince'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.LOCATION.PROVINCE_STATE' | translate }}
              {{ 'OTA.IS_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <!-- District -->
        <div class="custom-input flex w-full basis-1/3 flex-col gap-1">
          <label class="form-title">
            {{ 'OTA.BECOME_AN_AGENCY.CITY_DISTRICT' | translate }}
            <span class="form-require">*</span>
          </label>
          <p-dropdown
            [options]="districts"
            placeholder="{{ 'OTA.BECOME_AN_AGENCY.SELECT_A_CITY_DISTRICT' | translate }}"
            optionLabel="city_name"
            [filter]="true"
            filterBy="city_name"
            formControlName="companyDistrict"
            [ngClass]="{
              'is-invalid': cities && f['companyDistrict'].touched && f['companyDistrict'].errors
            }"
          >
            <ng-template pTemplate="selectedItem">
              <div
                *ngIf="f['companyDistrict'].value"
                class="flex items-center justify-center gap-2"
              >
                <div>{{ f['companyDistrict'].value.city_name }}</div>
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex items-center gap-2">
                <span>{{ country.city_name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <div
            *ngIf="cities && f['companyDistrict'].touched && f['companyDistrict'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['companyDistrict'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.LOCATION.CITY_DISTRICT' | translate }}
              {{ 'OTA.IS_REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- Currency & Timezone -->
      <div class="flex flex-col gap-4 min-[1100px]:flex-row">
        <!-- Currency -->
        <div class="custom-input flex w-full flex-col gap-1">
          <label class="form-title"
            >{{ 'OTA.PREFFERED_CURRENCY' | translate }} <span class="form-require">*</span></label
          >
          <div
            class="line-clamp-1 h-[2.75rem] truncate rounded-lg border border-[#d0d5dd] px-[1rem] py-[0.75rem] text-md"
            [ngClass]="{
              'text-palette-gray-700': f['currency']?.value,
              'text-palette-gray-400': !f['currency']?.value
            }"
          >
            {{ f['currency']?.value || ('OTA.auto_update_base_on_marketplace' | translate) }}
          </div>
          <!-- <p-dropdown
            [options]="currencyList"
            placeholder="{{ 'OTA.SELECT_CURRENCY' | translate }}"
            [filter]="true"
            [resetFilterOnHide]="true"
            optionValue="currency"
            filterBy="currency"
            formControlName="currency"
            [ngClass]="{
              'is-invalid': currencyList && f['currency'].touched && f['currency'].errors
            }"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="f['currency'].value" class="flex items-center justify-center gap-2">
                <div>{{ f['currency'].value }}</div>
              </div>
            </ng-template>
            <ng-template let-value pTemplate="item">
              <div class="flex items-center gap-2">
                <span>{{ value.currency }}</span>
              </div>
            </ng-template>
          </p-dropdown> -->
          <div
            *ngIf="currencyList && f['currency'].touched && f['currency'].errors"
            class="invalid-feedback"
          >
            <div *ngIf="f['currency'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.CURRENCY_REQUIRED_MSG' | translate }}
            </div>
          </div>
        </div>
        <!-- Timezone -->
        <div class="custom-input flex w-full flex-col gap-1">
          <label class="form-title"
            >{{ 'OTA.TIMEZONE' | translate }} <span class="form-require">*</span></label
          >
          <app-tzpicker-form-ctrl
            formControlName="timezone"
            [placeholder]="'OTA.SELECT_TIMEZONE' | translate"
            [ngClass]="{
              'is-invalid': f['timezone'].touched && f['timezone'].errors
            }"
          ></app-tzpicker-form-ctrl>
          <div *ngIf="f['timezone'].touched && f['timezone'].errors" class="invalid-feedback">
            <div *ngIf="f['timezone'].errors['required']" class="flex items-center gap-1">
              <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
              {{ 'OTA.TIMEZONE_REQUIRED_MSG' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="flex flex-col !gap-6 sm:!gap-0 sm:!grid grid-cols-3 !p-6 bg-white m-0 rounded-lg mt-2">
        <div class="col-span-1 flex flex-col gap-2">
            <h3 class="text-palette-gray-900 text-lg font-semibold leading-7">{{ 'OTA.BECOME_AN_AGENCY.BUSINESS_CONTACT' | translate }}</h3>
            <div class="text-sm leading-5 pr-12 text-palette-gray-700">{{ 'OTA.BECOME_AN_AGENCY.GENERAL_INFO_CONTENT' | translate }}</div>
        </div>
        <div class="col-span-2 flex flex-col gap-4">
            <div class="flex flex-col gap-1">
                <label for="fullName" class="form-title">{{ 'OTA.FULL_NAME' | translate }} <span class="form-require">*</span></label>
                <input
                    type="text"
                    id="fullName"
                    formControlName="fullName"
                    placeholder="{{ 'OTA.ENTER_FULL_NAME' | translate }}"
                    class="form-input"
                    [ngClass]="{ 'is-invalid': f['fullName'].touched && f['fullName'].errors }"
                />
                <div *ngIf="f['fullName'].touched && f['fullName'].errors" class="invalid-feedback">
                    <div *ngIf="f['fullName'].errors['required']" class="flex items-center gap-1">
                        <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                        {{ 'OTA.FULL_NAME' | translate }} {{ 'OTA.IS_REQUIRED' | translate }}
                    </div>
                </div>
            </div>
            <div class="flex gap-4 flex-col min-[1100px]:flex-row">
                <div class="flex flex-col gap-1 w-full">
                    <label for="businessEmail" class="form-title">{{ 'OTA.EMAIL' | translate }} <span class="form-require">*</span></label>
                    <input
                        type="email"
                        id="businessEmail"
                        formControlName="businessEmail"
                        placeholder="{{ 'OTA.ENTER_EMAIL' | translate }}"
                        class="form-input"
                        [ngClass]="{ 'is-invalid': f['businessEmail'].touched && f['businessEmail'].errors }"
                    />
                    <div *ngIf="f['businessEmail'].touched && f['businessEmail'].errors" class="invalid-feedback">
                        <div *ngIf="f['businessEmail'].errors['required']" class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ 'OTA.EMAIL' | translate }} {{ 'OTA.IS_REQUIRED' | translate }}
                        </div>
                        <div *ngIf="f['businessEmail'].errors['pattern']" class="flex items-center gap-1">
                            <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                            {{ 'OTA.EMAIL' | translate }} {{ 'OTA.IS_INVALID' | translate }}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-1 w-full">
                    <label for="businessPhone" class="form-title">{{ 'OTA.PHONE_NUMBER' | translate }} <span class="form-require">*</span></label>
                    <app-input-phone-number #businessPhoneRef [id]="'businessPhone'"></app-input-phone-number>
                </div>
            </div>
        </div>
    </div> -->

  <!-- Personal/Business documentation -->
  <div
    class="t-contract-attachments !mt-4 flex grid-cols-3 flex-col !gap-6 rounded-lg bg-white !p-6 sm:!grid sm:!gap-0"
  >
    <div class="col-span-1 flex flex-col gap-2">
      <h3 class="text-lg font-semibold leading-7 text-palette-gray-900">
        {{ 'OTA.BECOME_AN_AGENCY.DOCUMENT_TITLE' | translate }}
      </h3>
      <div class="pr-12 text-sm leading-5 text-palette-gray-700">
        {{ 'OTA.BECOME_AN_AGENCY.DOCUMENT_CONTENT' | translate }}
      </div>
    </div>
    <div class="col-span-2 flex flex-col gap-4">
      <!-- Role -->
      <div class="flex flex-col gap-1">
        <label for="role" class="form-title"
          >{{ 'OTA.CONTRACT_AGREEMENT.SELECT_ROLE' | translate }}
          <span class="form-require">*</span></label
        >
        <div class="mt-2 flex flex-col gap-4 md:flex-row">
          <div
            *ngFor="let role of roles; trackBy: roleTrackBy"
            class="flex h-[56px] w-full cursor-pointer items-center justify-between rounded-lg border px-4 py-3 md:w-1/2"
            [class.active-rule]="role.name === requestForm.value.role"
            [ngClass]="{
              'bg-primary-50 border-primary-600': role.name === requestForm.value.role,
            }"
            (click)="handleSelectRoleClick(role)"
          >
            <div class="flex items-center justify-start gap-3">
              <i [ngClass]="addClassActiveRole(role)" class="text-2xl leading-5 text-gray-500"> </i>
              <p
                [ngClass]="{
                  'text-primary-600': role.name === requestForm.value.role,
                }"
                class="text-md font-medium text-gray-700"
              >
                {{ role.label | translate }}
              </p>
            </div>
            <div>
              <img
                class="!h-5 !w-5"
                src="assets/icons/voucher-payment/{{
                  role.name === requestForm.value.role ? 'checkbox-active' : 'checkbox'
                }}.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Attachments -->
      <!-- TODO: Hide attachments section -->
      <div *ngIf="false" class="flex flex-col !gap-4 min-[1100px]:flex-row">
        <div class="flex w-full flex-col gap-1">
          <div class="flex items-center justify-between">
            <label for="" class="form-title">
              {{ 'OTA.CONTRACT_AGREEMENT.ATTACHMENTS' | translate }}
              <span class="form-require">*</span>
            </label>

            <div>
              <button
                *ngIf="
                  requestForm.value.role === businessText &&
                  uploadAttachBusiness.length <= maximumAttachBusiness
                "
                type="button"
                class="hover:bg-primary-50 flex items-center gap-1 rounded-lg px-2 py-1"
                (click)="
                  addUploadAttachments(additionalAttText, false, false, businessText.toLowerCase())
                "
              >
                <i class="sctr-icon-plus text-primary-600 text-base leading-[16px]"></i>
                <span class="text-primary-600 text-md font-semibold">
                  {{ 'OTA.CONTRACT_AGREEMENT.ADD_NEW' | translate }}
                </span>
              </button>
              <button
                *ngIf="
                  requestForm.value.role === personalText &&
                  uploadAttachPersonal.length <= maximumAttachPersonal
                "
                type="button"
                class="hover:bg-primary-50 flex items-center gap-1 rounded-lg px-2 py-1"
                (click)="
                  addUploadAttachments(additionalAttText, false, false, personalText.toLowerCase())
                "
              >
                <i class="sctr-icon-plus text-primary-600 text-base leading-[16px]"></i>
                <span class="text-primary-600 text-md font-semibold">
                  {{ 'OTA.CONTRACT_AGREEMENT.ADD_NEW' | translate }}
                </span>
              </button>
            </div>
          </div>

          <!-- Attachments support -->
          <div class="text-[12px] leading-4 text-gray-500">
            {{ 'OTA.BECOME_AN_AGENCY.ATTACHMENTS_SUPPORT' | translate }}
          </div>

          <div class="mt-3 flex flex-col gap-2">
            <span
              *ngIf="requestForm.value.role === businessText; then business; else personal"
            ></span>
            <ng-template #business>
              <div *ngFor="let uploadCpn of uploadAttachBusiness; let i = index">
                <app-upload
                  #uploader
                  [title]="getTranslatedLabel(uploadCpn.title)"
                  [type]="uploadCpn.type!"
                  [attach]="uploadCpn"
                  (emitOnClose)="onRemoveUploadAttachments(i, businessText.toLowerCase())"
                  (emitOnUpload)="onUploadFile($event, businessText.toLowerCase(), i, uploader)"
                  (emitOnDownload)="onDownloadFile($event)"
                  (emitOnFileTypeError)="onShowErrorForFileType()"
                >
                </app-upload>
              </div>
            </ng-template>
            <ng-template #personal>
              <div *ngFor="let uploadCpn of uploadAttachPersonal; let i = index">
                <app-upload
                  #uploader
                  [title]="getTranslatedLabel(uploadCpn.title)"
                  [type]="uploadCpn.type!"
                  [attach]="uploadCpn"
                  (emitOnClose)="onRemoveUploadAttachments(i, personalText.toLowerCase())"
                  (emitOnUpload)="onUploadFile($event, personalText.toLowerCase(), i, uploader)"
                  (emitOnDownload)="onDownloadFile($event)"
                  (emitOnFileTypeError)="onShowErrorForFileType()"
                >
                </app-upload>
              </div>
            </ng-template>
          </div>

          <div *ngIf="images?.length! > 0" class="mt-3 flex flex-col gap-2">
            <div class="flex flex-col">
              <div class="mb-1 ml-1 mt-2 grid grid-cols-4 gap-2">
                <div *ngFor="let item of images" class="relative">
                  <img
                    [src]="item.data!"
                    class="h-[8.5rem] w-[8.5rem] cursor-pointer rounded-[4px] border border-gray-200 md:h-[11.875rem] md:w-[16.25rem]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Button -->
  <div class="!my-6 flex justify-end !gap-4 text-sm font-semibold leading-5">
    <button
      type="button"
      (click)="handleClickCancel()"
      class="flex h-11 items-center justify-center rounded-lg border border-palette-gray-300 bg-white !px-5 !py-3 text-black hover:!bg-palette-gray-200"
    >
      {{ 'OTA.CANCEL' | translate }}
    </button>
    <button
      type="submit"
      class="relative flex h-11 min-w-[132px] items-center justify-center rounded-lg border !px-5 !py-3 text-white"
      [ngClass]="
        requestForm.valid && checkAllAttachmentsUploaded(requestForm.value.role.toLowerCase())
          ? 'cursor-pointer bg-palette-blue-600 hover:!bg-opacity-90'
          : 'cursor-auto bg-palette-blue-200'
      "
      [disabled]="
        !requestForm.valid || !checkAllAttachmentsUploaded(requestForm.value.role.toLowerCase())
      "
    >
      <ng-container *ngIf="isCallAPI; else showSubmitButtonContent">
        <div class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <app-loading></app-loading>
        </div>
      </ng-container>
      <ng-template #showSubmitButtonContent>
        {{ 'OTA.SUBMIT_REQUEST' | translate }}
      </ng-template>
    </button>
  </div>
</form>

<div
  *ngIf="isShowConfirmCancel"
  class="fixed bottom-0 left-0 right-0 top-0 z-[99999] flex items-center bg-black bg-opacity-40"
>
  <div class="relative m-auto flex max-w-[544px] flex-col !gap-6 rounded-md bg-white !p-6">
    <button
      (click)="handleClosePopup()"
      class="absolute !right-4 !top-4 flex !h-10 !w-10 items-center justify-center rounded-full !p-2 hover:bg-palette-gray-100"
    >
      <i class="sctr-icon-x-close text-xl"></i>
    </button>
    <h3 class="text-gray-palette-900 text-xl font-bold leading-7">
      {{ 'OTA.CANCEL_CONFIRMATION' | translate }}
    </h3>
    <p class="text-sm leading-5 text-[#475467]">
      {{ 'OTA.CANCEL_CONFIRMATION_CONTENT' | translate }}
    </p>
    <div class="flex justify-end gap-2 text-base leading-6 text-palette-gray-700">
      <button
        (click)="handleClosePopup()"
        class="rounded-md border border-palette-gray-300 bg-white !px-5 !py-2 font-semibold hover:!bg-palette-gray-100"
      >
        {{ 'OTA.NO' | translate }}
      </button>
      <button
        (click)="handleConfirm()"
        class="rounded-md bg-palette-red-600 !px-5 !py-2 font-semibold text-white hover:bg-palette-red-700"
      >
        {{ 'OTA.YES' | translate }}
      </button>
    </div>
  </div>
</div>
<app-toast></app-toast>
