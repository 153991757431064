import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { environment } from '@env/environment';
import {
  AutomakerCreateDTO,
  ResponseModelResponsePageRoutingDetailDTO,
  VehicleRequestDTO,
} from '@car-rental/angular-car-rental-service';
import { CarModelCreateDTO } from '@car-rental/angular-car-rental-service';

@Injectable({
  providedIn: 'root',
})
export class GetApiService {
  baseUrl: string = environment.BASE_API_URL;
  carOTAUrl: string = `${environment.BASE_API_URL}/car-rental/ota`;
  carOTAPromotion: string = `${environment.BASE_API_URL}/car-rental-promotion/`;
  carPublishUrl: string = `${environment.BASE_API_URL}/car-rental/vehicles/vehicle/`;
  carAutomarker: string = `${environment.BASE_API_URL}/car-rental/`;
  shipment: string = `${environment.BASE_API_URL}/shipment/`;
  payment: string = `${environment.BASE_API_URL}/payment/`;
  carOTAOrderUrl: string = `${environment.BASE_API_URL}/car-rental-order/`;
  carRentalOTAUrl: string = `${environment.BASE_API_URL}/car-rental-ota`;
  carRentalUrl: string = `${environment.BASE_API_URL}/car-rental`;
  chatApi: string = `${environment.BASE_API_URL}/chat`;
  ecommerceWalletUrl: string = `${environment.BASE_API_URL}/ecommerce-wallet`;
  bookingFlightDataUrl: string = `${environment.BASE_API_URL}/booking-flight-data`;

  constructor(private httpClient: HttpClient) {}
  createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }
  getVehicleSearchMix(
    start_time: number,
    end_time: number,
    long?: number,
    lat?: number,
    pageNum: number = 0,
    pageSize: number = 15,
    distance: number = 50,
    min_price?: number,
    max_price?: number,
    filter?: string,
    sort?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const listCar = `${this.carAutomarker}`;
    return this.httpClient.get(
      listCar +
        `vehicles/search/mix?start_time=${start_time}&end_time=${end_time}${
          long ? '&longitude=' + long : ''
        }${
          lat ? '&latitude=' + lat : ''
        }&pageNum=${pageNum}&pageSize=${pageSize}&distance=${distance}${
          min_price ? '&min_price=' + min_price : ''
        }${max_price ? '&max_price=' + max_price : ''}${
          filter ? '&filters=' + encodeURIComponent(filter) : ''
        }${sort ? '&sort=' + encodeURIComponent(sort) : ''}`
    );
  }
  getListCarOTA(
    pageNum: number,
    pageSize: number,
    filter?: string,
    sort?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const listCar = `${this.carOTAUrl}`;
    return this.httpClient.get(
      listCar +
        '/my-ota/search?pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        (filter ? '&filters=' + encodeURIComponent(filter!) : '') +
        (sort ? '&sort=' + encodeURIComponent(sort!) : ''),
      options
    );
  }
  getListCarByOtaId(
    otaId: string,
    pageNum: number,
    pageSize: number,
    filter?: string,
    sort?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const listCar = `${this.carOTAUrl}`;
    return this.httpClient.get(
      listCar +
        `/search?agencyId=${otaId}&pageNum=` +
        pageNum +
        '&pageSize=' +
        pageSize +
        (filter ? '&filters=' + encodeURIComponent(filter!) : '') +
        (sort ? '&sort=' + encodeURIComponent(sort!) : ''),
      options
    );
  }
  routingsSearchGet(
    agencyId: string,
    filter?: string,
    status?: string[],
    pageNum: number = 0,
    pageSize: number = 10,
    sort?: string
  ): Observable<ResponseModelResponsePageRoutingDetailDTO> {
    const options = { headers: this.createHeader() };
    const url = `${this.baseUrl}/car-rental`;
    return this.httpClient.get(
      url +
        '/routings/search?ignoreAtob=true&page_num=' +
        pageNum +
        '&page_size=' +
        pageSize +
        '&agency_id=' +
        agencyId +
        (filter ? '&filter=' + encodeURIComponent(filter!) : '') +
        (status ? '&status=' + encodeURIComponent(status[0]) : '') +
        (sort ? '&sort_by=' + encodeURIComponent(sort!) : ''),
      options
    );
  }

  // getListCarAndSearchOTA(keyword: string, tab: 'ALL'|'ACTIVE'|'OUT_OF_STOCK'|'DRAFT'|'TRASH', pinNum: string,pageNum: number, pageSize: number, filter?: string, sort?: string): Observable<any> {
  //   const options = { headers: this.createHeader() };
  //   const listCar = `${this.carOTAUrl}`;
  //   return this.httpClient.get(listCar + '/my-ota/search?pageNum='+ pageNum + '&pageSize=' + pageSize + (filter ? '&filters=' + encodeURIComponent(filter!) : '') + (sort ? '&sort=' + sort : ''), options);
  // }
  changePublish(agencyId: string, id: string, isPublish: boolean): Observable<any> {
    const options = { headers: this.createHeader() };
    const publish = `${this.carPublishUrl}`;
    return this.httpClient.put(
      publish + id + `/publish?agencyId=${agencyId}&is=` + String(isPublish),
      {},
      options
    );
  }
  getListAutomarker(pageNum: number, pageSize: number): Observable<any> {
    const options = { headers: this.createHeader() };
    const autoMarker = `${this.carAutomarker}`;
    return this.httpClient.get(
      autoMarker + 'automakers/list?pageNum=' + pageNum + '&pageSize=' + pageSize,
      options
    );
  }
  postAutomaker(body: AutomakerCreateDTO): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.post(vehicle + 'automakers', body, options);
  }
  getListCarModel(pageNum: number, pageSize: number): Observable<any> {
    const options = { headers: this.createHeader() };
    const carModel = `${this.carAutomarker}`;
    return this.httpClient.get(
      carModel + 'car-models/list?pageNum=' + pageNum + '&pageSize=' + pageSize,
      options
    );
  }
  getFuel(): Observable<any> {
    const options = { headers: this.createHeader() };
    const fuel = `${this.carAutomarker}`;
    return this.httpClient.get(fuel + 'fuels', options);
  }
  getCountries(): Observable<any> {
    const options = { headers: this.createHeader() };
    const country = `${this.shipment}`;
    return this.httpClient.get(country + '/address/countries', options);
  }
  getProvince(countryId: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const province = `${this.shipment}`;
    return this.httpClient.get(province + '/address/provinces?countryId=' + countryId, options);
  }
  getDistrict(provinceId: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const district = `${this.shipment}`;
    return this.httpClient.get(district + '/address/districts?provinceId=' + provinceId, options);
  }
  getAmenity(type: boolean, lang: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const amenity = `${this.carAutomarker}`;
    return this.httpClient.get(
      amenity + 'amenities?is_advanced=' + String(type) + '&lang=' + lang,
      options
    );
  }
  getAllAmenity(
    pageNum: number,
    pageSize: number,
    filter?: string,
    sort?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const advAmenity = `${this.carAutomarker}`;
    return this.httpClient.get(
      advAmenity +
        'amenities/search?pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        (filter ? '&filters=' + encodeURIComponent(filter!) : '') +
        (sort ? '&sort=' + encodeURIComponent(sort!) : ''),
      options
    );
  }
  getMyAdvancedAmenity(
    pageNum: number,
    pageSize: number,
    filter?: string,
    sort?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const advAmenity = `${this.carAutomarker}`;
    return this.httpClient.get(
      advAmenity +
        'amenities/my-advanced?pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        (filter ? '&filters=' + encodeURIComponent(filter!) : '') +
        (sort ? '&sort=' + encodeURIComponent(sort!) : ''),
      options
    );
  }
  getDocumentType(): Observable<any> {
    const options = { headers: this.createHeader() };
    const document = `${this.carAutomarker}`;
    return this.httpClient.get(document + 'document-types', options);
  }
  getVehicle(filter: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.get(
      vehicle +
        'vehicles/search?pageNum=0&pageSize=1000&filters=' +
        (filter ? '&filters=' + encodeURIComponent(filter!) : ''),
      options
    );
  }
  searchVehicleV2(
    filters: string,
    page: string = '0',
    size: string = '10',
    sort: string = ''
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const searchV2Api = `${this.carAutomarker}/vehicles/search/v2`;

    return this.httpClient.get(
      searchV2Api +
        '?filters=' +
        encodeURIComponent(filters) +
        '&page_num=' +
        page +
        '&page_size=' +
        size +
        '&sort=' +
        encodeURIComponent(`${sort},price|double`),
      options
    );
  }
  searchVehicleAirportTransfer(
    filters: string,
    page: string = '0',
    size: string = '10',
    sort: string = ''
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const searchV2Api = `${this.carAutomarker}/vehicles/search/airport-transfer`;

    return this.httpClient.get(
      searchV2Api +
        '?filters=' +
        encodeURIComponent(filters) +
        '&page_num=' +
        page +
        '&page_size=' +
        size +
        '&sort=' +
        encodeURIComponent(`${sort},price|double`),
      options
    );
  }
  postCar(agencyId: string, body: VehicleRequestDTO): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.post(vehicle + `vehicles?agencyId=${agencyId}`, body, options);
  }
  findVehicleDetail(id: string, lang: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.get(vehicle + 'vehicles/' + id + '?lang=' + lang, options);
  }
  getVehicleDetail(id: string, lang: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.get(vehicle + 'vehicles/' + id + '/details?lang=' + lang, options);
  }
  getPromotionList(
    ota_id: string,
    tab: string,
    pageNum: number,
    pageSize: number,
    filters?: string,
    sort?: string,
    voucher_type?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const carOTAPromotion = `${this.carOTAPromotion}`;
    return this.httpClient.get(
      carOTAPromotion +
        'vouchers/management/' +
        ota_id +
        '?tab=' +
        tab +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        (filters ? '&filters=' + encodeURIComponent(filters) : '') +
        '&sort=' +
        sort,
      options
    );
  }
  postCarModel(body: any): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.post(vehicle + 'car-models', body, options);
  }
  postAmenity(amenity_name: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const amenity = `${this.carAutomarker}`;
    return this.httpClient.post(amenity + 'amenities?name=' + String(amenity_name), options);
  }
  putAmenity(id: string, update_amenity_name: string) {
    const options = { headers: this.createHeader() };
    const amenity = `${this.carAutomarker}`;
    return this.httpClient.put(
      amenity + 'amenities/' + id + '?name=' + String(update_amenity_name),
      options
    );
  }
  deleteAmenity(id: string) {
    const options = { headers: this.createHeader() };
    const amenity = `${this.carAutomarker}`;
    return this.httpClient.delete(amenity + 'amenities/' + String(id), options);
  }
  getListSeat(): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.get(vehicle + 'seats', options);
  }
  getCarType(): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.get(vehicle + 'car-models/types', options);
  }
  updateAutomaker(id: string, body: any): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.put(vehicle + 'automakers/' + id, body, options);
  }
  deleteAutomaker(id: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.delete(vehicle + 'automakers/' + id, options);
  }
  updateCarModel(id: string, body: any): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.put(vehicle + 'car-models/' + id, body, options);
  }
  deleteCarModel(id: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const vehicle = `${this.carAutomarker}`;
    return this.httpClient.delete(vehicle + 'car-models/' + id, options);
  }
  getPaymentV2HistoryByToken(token: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const paymentV2 = `${this.payment}v2/payments/`;
    return this.httpClient.get(paymentV2 + 'token/' + token, options);
  }
  topUpBalanceByAgencyId(agencyId: string, amount: number) {
    const options = { headers: this.createHeader() };
    const carOrders = `${this.carOTAOrderUrl}orders/`;
    return this.httpClient.post(carOrders + agencyId + '/top-up?amount=' + String(amount), options);
  }
  getReviewByVehicleIds(
    agency_id: string,
    vehicle_ids?: string[],
    customer_name?: string,
    service_type?: string,
    departure_date?: string,
    reservation_code?: string,
    rating_type?: string,
    sort_by?: string
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const idsString = vehicle_ids?.map((id) => `&vehicle_ids=${id}`);

    return this.httpClient.get(
      environment.BASE_API_URL +
        '/car-rental-ota/reviews' +
        '?agency_id=' +
        agency_id +
        idsString?.join('') +
        (customer_name ? '&customer_name=' + customer_name : '') +
        (service_type ? '&service_type=' + service_type : '') +
        (departure_date ? '&departure_date=' + departure_date : '') +
        (reservation_code ? '&reservation_code=' + reservation_code : '') +
        (rating_type ? '&rating_type=' + rating_type : '') +
        (sort_by ? '&sort_by=' + sort_by : ''),
      options
    );
  }
  getOrderCodeList(agencyId: string, orderIdList: string[]): Observable<any> {
    const options = { headers: this.createHeader() };
    const idsString = orderIdList?.map((id) => `&order_ids=${id}`);
    const orders = `${this.carOTAOrderUrl}orders/`;
    return this.httpClient.get(
      orders + 'list?agency_id=' + agencyId + idsString?.join(''),
      options
    );
  }
  getOwnerCarInfo(agencyId: string): Observable<any> {
    const options = { headers: this.createHeader() };
    const carOwner = `${this.carRentalOTAUrl}/agencies/${agencyId}/owner-info/`;
    return this.httpClient.get(carOwner, options);
  }
  getReviewByCarOwner(
    agencyId: string,
    page_num: number,
    page_size: number,
    filter?: string,
    sort_by: string = '-created_at|LocalDateTime'
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const carOwnerReview = `${this.carRentalUrl}/reviews/search`;
    return this.httpClient.get(
      carOwnerReview +
        '?agency_id=' +
        agencyId +
        (filter ? '&filters=' + filter : '') +
        '&page_num=' +
        page_num +
        '&page_size=' +
        page_size +
        '&sort_by=' +
        encodeURIComponent(sort_by),
      options
    );
  }
  getListActiveCarOwner(
    agencyId: string,
    page_num: number,
    page_size: number,
    filter?: string,
    sort_by: string = '-created_at|LocalDateTime'
  ): Observable<any> {
    const options = { headers: this.createHeader() };
    const carOwnerReview = `${this.carRentalUrl}/vehicles/owner/search`;
    return this.httpClient.get(
      carOwnerReview +
        '?agency_id=' +
        agencyId +
        (filter ? '&filters=' + encodeURIComponent(filter) : '') +
        '&page_num=' +
        page_num +
        '&page_size=' +
        page_size +
        '&sort_by=' +
        encodeURIComponent(sort_by),
      options
    );
  }
  getListCarOwnerAirport(
    agencyId: string,
    page_num: number = 0,
    page_size: number = 10,
    filter?: string,
    sort_by: string = '-created_at|LocalDateTime'
  ) {
    const options = { headers: this.createHeader() };
    const carOwnerReview = `${this.carRentalUrl}/vehicles/owner/airport-transfer/search`;
    return this.httpClient.get(
      carOwnerReview +
        '?agency_id=' +
        agencyId +
        (filter ? '&filters=' + encodeURIComponent(filter) : '') +
        '&page_num=' +
        page_num +
        '&page_size=' +
        page_size +
        '&sort_by=' +
        encodeURIComponent(sort_by),
      options
    );
  }
  getAgencyChatSessionsCount(agencyId: string, status: string, module: string) {
    const options = { headers: this.createHeader() };
    const chatGeneralApi = `${this.chatApi}/sessions/support/agency/${agencyId}/status/${status}/count?module=${module}`;
    return this.httpClient.get(chatGeneralApi, options);
  }
  getReviewOverViewCarOwner(agencyId: string) {
    const options = { headers: this.createHeader() };
    const carOwnerReview = `${this.carRentalUrl}/reviews/overviews`;
    return this.httpClient.get(carOwnerReview + '?agency_id=' + agencyId);
  }
  getReviewOverViewCarDetail(agencyId: string) {
    const options = { headers: this.createHeader() };
    const carOwnerReview = `${this.carRentalUrl}/reviews/vehicles/overviews`;
    return this.httpClient.get(carOwnerReview + '?vehicle_id=' + agencyId);
  }
  exportBalanceHistory(
    walletId: string,
    startDate: number | undefined,
    endDate: number | undefined,
    pageNum: number = 0,
    pageSize: number = 10,
    sort: string = '-created_at|Long',
    lang: string = 'en',
    filters: string = '',
    moduleType: string = ''
  ): Observable<any> {
    const url = `${this.ecommerceWalletUrl}/balance-histories/${walletId}/module/log/download`;
    `https://api-dev-tmtrav.tma-swerp.com/ecommerce-wallet/balance-histories/f5fd8e63-88b7-4ada-8eec-62008dac272b/
module/log/download?start_time=1731888000000&end_time=1732579199000&pageNum=0&pageSize=2000&language=en&moduleType=SHOP`;
    return this.httpClient.get(
      url +
        '?start_time=' +
        startDate +
        '&end_time=' +
        endDate +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        '&sort=' +
        encodeURIComponent(sort) +
        '&lang=' +
        lang +
        '&filters=' +
        encodeURIComponent(filters) +
        '&moduleType=' +
        moduleType,
      { responseType: 'blob' }
    );
  }
  exportTransactionHistory(
    walletId: string,
    transactionType: string,
    startDate: number | undefined,
    endDate: number | undefined,
    pageNum: number = 0,
    pageSize: number = 10,
    sort: string = '-created_at|Long',
    lang: string = 'en',
    filters: string = '',
    module: string = ''
  ): Observable<any> {
    const url = `${this.ecommerceWalletUrl}/transaction-histories/${walletId}/${transactionType}/module/download`;

    return this.httpClient.get(
      url +
        '?start_time=' +
        startDate +
        '&end_time=' +
        endDate +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        pageSize +
        '&sort=' +
        encodeURIComponent(sort) +
        '&lang=' +
        lang +
        '&filters=' +
        encodeURIComponent(filters) +
        '&module=' +
        module,
      { responseType: 'blob' }
    );
  }
  searchMyTransactionHistory(
    walletId: string,
    transactionType: string,
    startDate: number | undefined,
    endDate: number | undefined,
    pageNum: number = 0,
    pageSize: number = 10,
    sort: string = '-created_at|Long',
    lang: string = 'en',
    filters: string = ''
  ): Observable<any> {
    const url = `${this.ecommerceWalletUrl}/transaction-histories/${walletId}/${transactionType}/search?`;
    let searchUrl: string = `${url}&pageNum=${pageNum}&pageSize=${pageSize}&sort=${encodeURIComponent(
      sort
    )}&language=${lang}&filters=${encodeURIComponent(filters)}`;
    if (startDate) {
      searchUrl = searchUrl + `&start_time=${startDate}`;
    }
    if (endDate) {
      searchUrl = searchUrl + `&end_time=${endDate}`;
    }
    return this.httpClient.get(searchUrl);
  }
  searchLogsBalanceHistory(
    walletId: string,
    startDate: number | undefined,
    endDate: number | undefined,
    pageNum: number = 0,
    pageSize: number = 10,
    sort: string = '-created_at|Long',
    lang: string = 'en',
    filters: string = ''
  ): Observable<any> {
    const url = `${this.ecommerceWalletUrl}/balance-histories/${walletId}/logs/search?`;
    let searchUrl: string = `${url}&pageNum=${pageNum}&pageSize=${pageSize}&sort=${encodeURIComponent(
      sort
    )}&language=${lang}&filters=${encodeURIComponent(filters)}`;
    if (startDate) {
      searchUrl = searchUrl + `&start_time=${startDate}`;
    }
    if (endDate) {
      searchUrl = searchUrl + `&end_time=${endDate}`;
    }
    return this.httpClient.get(searchUrl);
  }

  searchAirportByIata(iataCode: string, lang: string, pageNum: number = 1, pageSize: number = 10) {
    const options = { headers: this.createHeader() };
    const suggestSearchAirportApi = `${this.bookingFlightDataUrl}/suggest-search?page_num=${pageNum}&page_size=${pageSize}&keyword=${iataCode}&language=${lang}`;
    return this.httpClient.get(suggestSearchAirportApi, options).pipe(retry(1));
  }
  getAirportByKeyword(
    page_num: number,
    page_size: number,
    keyword: string,
    language: string = 'en'
  ): Observable<any> {
    const url = `${this.bookingFlightDataUrl}/suggest-search`;
    return this.httpClient.get(
      url +
        `?page_num=${page_num}&page_size=${page_size}&keyword=${encodeURIComponent(
          keyword
        )}&language=${language}`
    );
  }
  getCurrentRegion(): Observable<any> {
    const url = `${this.baseUrl}/regional/me`;
    return this.httpClient.get(url);
  }
  getExchangRateTwoCurrency(from: string, to: string): Observable<any> {
    const url = `${this.baseUrl}/billing/exchange-rate/v2/${from}/${to}`;
    return this.httpClient.get(url);
  }

  getSupportedCountries(): Observable<any> {
    const url = `${environment.BASE_API_URL}/billing/supported-country`;
    return this.httpClient.get(url);
  }
}
