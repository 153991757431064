import {
  Component,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ICarItem as ICarItem, PropertyAmenityList } from '@modules/car-booking/models/hotel.model';
import { Router } from '@angular/router';
import { createSlug } from '@shared/utils/helper';
import { MessageService } from 'primeng/api';
import { BookMarkService } from '@modules/car-booking/services/bookmark/bookmark.service';
import { BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { ToastType } from '@shared/enum/toast-type';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Policy } from '@modules/car-booking/models/hotel-result.model';

const { BloomFilter } = require('@soctrip-common/soctrip-algorithm/dist/api.js');
@Component({
  selector: 'app-hot-deal-hotel-card',
  templateUrl: './hot-deal-hotel-card.component.html',
  styleUrls: ['./hot-deal-hotel-card.component.scss'],
  providers: [MessageService],
})
export class HotDealHotelCardComponent implements OnInit, OnDestroy {
  @ViewChildren('hotelName') hotelNameElements: QueryList<ElementRef>;
  @Input() item: ICarItem;
  @Input() with_without_driver: string;

  tagList: Policy[] = [];
  private ngUnsubscribe = new Subject<void>();
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;

  hotelNameArray: any;
  pipe = new DatePipe('en-US');
  invalidIconName: string = 'check-circle-broken';
  isBookmark: boolean;
  originPrice: any;
  price: any;
  imageUrl: string;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  visible: boolean = false;
  userCurrency: string;
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private bookMarkService: BookMarkService,
    private messageService: MessageService,
    private bookmarkControllerService: BookmarkControllerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userCurrency = localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA)
      ? JSON.parse(localStorage.getItem(environment.CURRENCY_CONVERSATION_DATA) || '')?.currency
      : 'USD';
    this.handleSyncBookmark();
    if (this.userCurrency === this.item.currency) {
      this.originPrice = this.item.original_price_canal;
      this.price = this.item.price_canal;
    } else {
      this.originPrice = this.item.original_price;
      this.price = this.item.price;
    }

    if (this.item?.avatar?.id) {
      this.imageUrl = this.baseImageId + '/' + this.item.avatar.id;
    } else {
      this.imageUrl = 'assets/images/test/Thumbnail.png';
    }
    this.calculatePolicy();
    this.checkBookmark();
  }

  calculatePolicy() {
    if (this.item.is_self_pickup) {
      this.tagList.push({
        name: this.translateService.instant('BOOKING_HOME.car_card.self_pickup'),
        type: 'info',
      });
    }

    if (this.item.is_home_delivery) {
      this.tagList.push({
        name: this.translateService.instant('BOOKING_HOME.car_card.home_delivery'),
        type: 'error',
      });
    }
    this.tagList.push({
      name: this.translateService.instant(
        this.item?.is_automatic_transmission
          ? 'BOOKING_HOME.car_card.auto_trans'
          : 'BOOKING_HOME.car_card.manual_trans'
      ),
      type: 'warning',
    });
  }

  handleSyncBookmark(): void {
    this.bookMarkService
      .getRecentItem()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.object_id === this.item.id && data.section === 'featured') {
          if (data.type === 'create') {
            this.isBookmark = true;
          } else {
            this.isBookmark = false;
          }
        }
      });
  }

  ngAfterViewInit() {
    this.hotelNameArray = this.hotelNameElements.toArray();
    this.handleShowTooltip(this.hotelNameArray, 'hotdeals-hotel-name');
    window.addEventListener('resize', () => {
      this.handleShowTooltip(this.hotelNameArray, 'hotdeals-hotel-name');
    });
  }

  checkBookmark() {
    if (!!!this.bookMarkService?.bloomFilter) return;
    this.bookMarkService.apiSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (success) => {
        if (success) {
          let bloomFilter = BloomFilter.load(this.bookMarkService.bloomFilter);
          const exists = bloomFilter.has(this.item.id);
          if (exists) {
            this.bookmarkControllerService.bookmarksObjectIdIsExistedGet(this.item.id).subscribe({
              next: (res) => {
                if (res.success) {
                  this.isBookmark = true;
                }
              },
            });
          } else {
            this.isBookmark = false;
          }
        }
      },
    });
  }

  handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      //check if element was truncated
      const isTruncated = val.nativeElement.scrollHeight > val.nativeElement.clientHeight;
      //add class to show tooltip
      if (isTruncated) this.renderer.addClass(val.nativeElement, className);
      else this.renderer.removeClass(val.nativeElement, className);
    }
  }

  formatCurrency(price: number) {
    return price.toLocaleString('en-US');
  }
  getAddress(item: any) {
    return item.location?.district?.name + ', ' + item.location?.province?.name;
  }
  get promotion() {
    try {
      return Math.round(((this.item.original_price - this.item.price) / this.item.original_price) * 100);
    } catch (error) {
      return 0;;
    }
  }
  handleBookmark(event: any, item: any) {
    event.stopPropagation();
    if (!this.bookMarkService.isAllow) {
      this.messageService.add({
        key: 'hd',
        severity: ToastType.WARNING,
        detail: this.translateService.instant('toast_save_car.login_before_bookmark'),
      });
    } else {
      if (this.isBookmark) {
        this.isBookmark = false;
        this.bookMarkService
          .deleteBookmark(item.id, 'hotDeal')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe();
        this.bookMarkService.deleteItemOfMySavedList(item.id);
        this.messageService.add({
          key: 'hd',
          severity: ToastType.SUCCESS,
          detail: this.translateService.instant('toast_save_car.unsave_success', {
            param: this.item.vehicle_info?.car_model_name,
          }),
        });
        this.visible = false;
      } else {
        this.isBookmark = true;
        this.bookMarkService
          .createBookMark(item.id, 'hotDeal')
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe();
        this.bookMarkService.addItemToMySavedList(item);
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('toast_save_car.save_success', {
            param: this.item.vehicle_info?.car_model_name,
          }),
          key: 'save',
        });
      }
    }
  }
  viewDetails(id: string, carItem: any) {
    const location = carItem?.location?.district?.name || carItem?.location?.province?.name;
    this.router.navigate(['/booking/car/details/', id, createSlug(carItem.name)], {
      queryParams: {
        destination: location ?? null,
        checkin: this.pipe.transform(new Date(), 'yyyy-MM-dd'),
        checkout: this.pipe.transform(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          'yyyy-MM-dd'
        ),
        rental_type: this.with_without_driver==='with' ? 'is_with_driver' : 'is_without_driver',
        property_id: id,
      },
    });
  }

  openMap(event: any, item: ICarItem) {
    event.stopPropagation();
    const queryParams = {
      property_id: item.id,
    };
    this.router.navigate(['/booking/car/map', this.item.description], {
      queryParams,
    });
  }

  renderAmenityList(property_amenity_list: PropertyAmenityList[]) {
    let availableList = property_amenity_list.filter(
      (item) => item.amenity_icon !== this.invalidIconName
    );
    return availableList;
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/test/Thumbnail.png';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openDialog() {
    this.visible = true;
  }
  closeDialog() {
    this.visible = false;
  }
}
