import { NgModule } from '@angular/core';
import { CountdownFormatPipe } from './pipes/coundown-format.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { SortByInteractionsPipe } from './pipes/sort-by-interactions.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { BytesConversionPipe } from './pipes/bytes-conversion.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SafeURLPipe } from './pipes/safe-url.pipe';

import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { FeaturedHotelCardComponent } from './components/featured-hotel-card/featured-hotel-card.component';
import { HotDealHotelCardComponent } from './components/hot-deal-hotel-card/hot-deal-hotel-card.component';
import { ToastComponent } from './components/toast/toast.component';
import { Toast, ToastModule } from 'primeng/toast';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { OverlayModule } from 'primeng/overlay';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';

import { HotelResultCardComponent } from './components/hotel-result-card/hotel-result-card.component';
import { BookingMenuComponent } from './components/booking-menu/booking-menu.component';
import { HotelPageRoomTypeComponent } from './components/hotel-page-room-type/hotel-page-room-type.component';
import { CustomerReviewCardComponent } from './components/customer-review-card/customer-review-card.component';
import { PaginationCustomComponent } from './components/pagination-custom/pagination-custom.component';
import { TagComponent } from './components/tag/tag.component';
import { ShowImageModalComponent } from './components/show-image-modal/show-image-modal.component';
import { HotelResultCardOnMap } from './components/hotel-result-card-on-map/hotel-result-card-on-map.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { PriceDetailsCheckoutComponent } from './components/price-details-checkout/price-details-checkout.component';
import { CustomizePaginationComponent } from './components/customize-pagination/customize-pagination.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { PopupRecommendSignIn } from './components/popup-recommend-sign-in/popup-recommend-sign-in.component';
import { PostDialogComponent } from './components/share-hotel/post-dialog/post-dialog.component';
import { ShareHotelComponent } from './components/share-hotel/share-hotel.component';
import { SignInRequestDialogComponent } from './components/sign-in-request-dialog/sign-in-request-dialog.component';
import { PopupCtaComponent } from './components/popup-cta/popup-cta.component';
import { PriceFormatComponent } from './components/price-format/price-format.component';
import { RatingScoreComponent } from './components/rating-score/rating-score.component';
import { CommonIconText } from './components/common-icon-text/common-icon-text.component';
import { TooltipModuleBooking } from './components/tooltip/tooltip.module';
import { StarComponent } from '@shared/components/star/star.component';
import { MapMarkerPopupComponent } from '@modules/car-booking/components/search/map-marker-popup/map-marker-popup.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PropertyAmenitiesService } from '@modules/car-booking/services/ota/property-amenities.service';
import { PopupQrCodeComponent } from './components/popup-qr-code/popup-qr-code.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InputPhoneNumberComponent } from './components/input-phone-number/input-phone-number.component';
import { PropertyAmenitiesSectionComponent } from './components/OTA/property-amenities-section/property-amenities-section.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MeesageErrorFormModule } from './components/meesage-error-form/meesage-error-form.module';
import { AppCalenderSelectComponent } from './components/calendar-select/calendar-select.component';
import { CarCancellationPolicyComponent } from './components/cancellation-policy/cancellation-policy.component';
import { RentalDocumentComponent } from './components/rental-document/rental-document.component';
import { RentContactInfomationComponent } from './components/rent-contact-infomation/rent-contact-infomation.component';
import { JourneyInformationComponent } from './components/journey-information/journey-information.component';
import { OwwnerContactInformationComponent } from './components/owner-contact-info/owner-contact-info.component';
import { CarHighlightComponent } from './components/car-highlight/car-highlight.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { CalendarModule } from 'primeng/calendar';
import { CalendarFilterComponent } from './components/calendar-filter/calendar-filter.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { PaginatorModule } from 'primeng/paginator';
import { UnderTestComponent } from './components/under-test/under-test.component';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { UploadComponent } from './components/upload/upload.component';
import { ReportComponent } from './components/report-dialog/report.component';
import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DividerModule } from 'primeng/divider';
import { DialogSaveListCarComponent } from './components/dialog-save-list-car/dialog-save-list-car.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { SoctripTranslate } from './pipes/soctripTranslate.pipe';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CustomTimePipe } from './pipes/custom-time.pipe';
import { CustomDateTimePipe } from './pipes/custom-date-time.pipe';
import { ImagePathPipe } from './pipes/image-path.pipe';
import { VideoPathPipe } from './pipes/video-path.pipe';
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';
import { HeaderNotificationsComponent } from './components/header-notifications/header-notifications.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { TzOnlyPipe } from './pipes/tz-only.pipe';
import { LocalizedLangDescStringPipe } from './pipes/localized-lang-desc-string.pipe';
import { AirportServiceCarComponent } from './components/airport-service-car/airport-service-car.component';
import { BigJsMathPipe } from './pipes/bigjs-math.pipe';
import { JourneyInformationCarWithDriverComponent } from './components/journey-information-car-with-driver/journey-information-car-with-driver.component';

const sharedPipes = [
  CountdownFormatPipe,
  TruncateStringPipe,
  RelativeTimePipe,
  CustomDatePipe,
  CustomTimePipe,
  // CustomDateTimePipe,
  RelativeTimePipe,
  NumberFormatPipe,
  SortByInteractionsPipe,
  SortPipe,
  SlugifyPipe,
  BytesConversionPipe,
  FilterPipe,
  ShortNumberPipe,
  SafeURLPipe,
  SoctripTranslate,
];
const shareProviders = [
  Toast,
  DialogService,
  DynamicDialogRef,
  PropertyAmenitiesService,
  ConfirmationService,
];
const shareModules = [
  CurrencyConversionPipe,
  InputSwitchModule,
  DialogModule,
  ButtonModule,
  InputTextModule,
  FormsModule,
  ReactiveFormsModule,
  OverlayPanelModule,
  BsDatepickerModule,
  ModalModule,
  DropdownModule,
  ToastModule,
  TagModule,
  TooltipModule,
  GalleriaModule,
  OverlayModule,
  TabViewModule,
  TabMenuModule,
  TranslateModule,
  ProgressSpinnerModule,
  TooltipModuleBooking,
  InputNumberModule,
  CheckboxModule,
  RadioButtonModule,
  SkeletonModule,
  ConfirmDialogModule,
  MeesageErrorFormModule,
  OverlayPanelModule,
  CalendarModule,
  PaginatorModule,
  ChipModule,
  InputTextareaModule,
  DividerModule,
  PickerModule,
];
const shareComponents = [
  ModalComponent,
  FeaturedHotelCardComponent,
  HotDealHotelCardComponent,
  ToastComponent,
  SearchBarComponent,
  HotelResultCardComponent,
  AirportServiceCarComponent,
  BookingMenuComponent,
  HotelPageRoomTypeComponent,
  CustomerReviewCardComponent,
  PaginationCustomComponent,
  TagComponent,
  ShowImageModalComponent,
  HotelResultCardOnMap,
  TabMenuComponent,
  PriceDetailsCheckoutComponent,
  CustomizePaginationComponent,
  TimePickerComponent,
  PopupRecommendSignIn,
  PostDialogComponent,
  ShareHotelComponent,
  SignInRequestDialogComponent,
  PopupCtaComponent,
  PriceFormatComponent,
  RatingScoreComponent,
  CommonIconText,
  StarComponent,
  PopupQrCodeComponent,
  MapMarkerPopupComponent,
  MapMarkerPopupComponent,
  LoadingComponent,
  InputPhoneNumberComponent,
  PropertyAmenitiesSectionComponent,
  PaginationCustomComponent,
  AppCalenderSelectComponent,
  CarCancellationPolicyComponent,
  RentalDocumentComponent,
  RentContactInfomationComponent,
  JourneyInformationComponent,
  OwwnerContactInformationComponent,
  CarHighlightComponent,
  CalendarFilterComponent,
  PaginatorComponent,
  TableEmptyComponent,
  UnderTestComponent,
  ReportComponent,
  MediaPreviewComponent,
  HeaderNotificationsComponent,
  ConfirmationComponent,
  JourneyInformationCarWithDriverComponent,
];
@NgModule({
  declarations: [sharedPipes, shareComponents],
  exports: [
    CommonModule,
    sharedPipes,
    shareComponents,
    shareModules,
    UploadComponent,
    SoctripTranslate,
    BigJsMathPipe,
  ],
  providers: [shareProviders, PropertyAmenitiesService],
  imports: [
    CommonModule,
    shareModules,
    UploadComponent,
    DialogSaveListCarComponent,
    DebounceClickDirective,
    ImagePathPipe,
    VideoPathPipe,
    CustomDateTimePipe,
    TzOnlyPipe,
    LocalizedLangDescStringPipe,
    BigJsMathPipe,
  ],
})
export class SharedModule {}
