import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  uploadFiles(formData: FormData) {
    return this.http.post<any>(`${environment.baseURL}/storage/files/upload-files`, formData);
  }
}
