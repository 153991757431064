import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor() {}
  private profile$ = new BehaviorSubject<any>(this.getUserInfoFromLocalStorage());

  getData() {
    return this.profile$.asObservable();
  }

  setData(data: any) {
    this.profile$.next(data);
  }
  getUserInfoFromLocalStorage() {
    const userInfoString = localStorage.getItem(environment.USER_PROFILE);
    if (userInfoString && this.isValidJSON(userInfoString)) return JSON.parse(userInfoString);
  }
  isValidJSON(str: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  get isLogged(): Observable<boolean> {
    return this.getData().pipe(map((profile) => !!profile?.id));
  }
}
